.table-jobs {
    border-collapse: collapse;
    border-top: $sds-pax-size-20 solid $sds-pax-color-accent-350;
    font-size: $sds-pax-font-size-text-s;
    margin-top: $sds-pax-size-100;
    width: 100%;

    caption {
        @include visually-hidden;
    }

    thead {
        @include visually-hidden;

        @media (min-width: 40em), print {
            @include element-visible;
        }

        th {
            color: $sds-pax-color-accent-350;
            font-weight: 900;
            text-align: left;
            white-space: nowrap;
        }
    }

    tbody {
        border-top: $sds-pax-size-10 solid $sds-pax-color-accent-350;
        display: block;
        padding: $sds-pax-size-60 0;
        position: relative;

        &:first-of-type {
            border-top: 0;
        }

        &:last-of-type {
            border-bottom: $sds-pax-size-10 solid $sds-pax-color-accent-350;
        }

        @media (min-width: 40em), print {
            display: table-row-group;
            padding: 0;

            &:first-of-type {
                border-top: $sds-pax-size-10 solid $sds-pax-color-accent-350;
            }
        }
    }

    tr {
        display: block;

        @media (min-width: 40em), print {
            display: table-row;
        }
    }

    th,
    td {
        text-align: left;
        vertical-align: baseline;

        @media (min-width: 40em), print {
            padding: $sds-pax-size-60 $sds-pax-size-60 $sds-pax-size-60 0;
        }
    }

    .table-jobs__details {
        padding-right: 0;
        text-align: right;
        white-space: nowrap;

        &:empty {
            display: none;
        }

        @media (max-width: 39.9375em) {
            padding-top: $sds-pax-size-40;
        }
    }

    th {
        font-weight: bold;
    }

    .table-jobs__title {
        @media (max-width: 39.9375em) {
            font-size: $sds-pax-size-60;
            padding-bottom: $sds-pax-size-40;
        }
    }

    [data-industry],
    [data-education-requirements] {
        display: block;

        @media (min-width: 40em), print {
            display: table-cell;
            width: 20%;
        }

        &::before {
            content: attr(data-industry);
            display: inline-block;
            margin-right: $sds-pax-size-50;
            width: 40%;

            @media (min-width: 40em), print {
                content: none;
            }
        }
    }

    [data-education-requirements] {
        &::before {
            content: attr(data-education-requirements);

            @media (min-width: 40em), print {
                content: none;
            }
        }
    }
}
