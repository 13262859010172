.cases {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -#{$sds-pax-size-60};

    > * {
        flex: 1 1 auto;
        margin: $sds-pax-size-40;
        width: calc(100% - $sds-pax-size-60);

        @media (min-width: 40em) {
            margin: $sds-pax-size-60;
            width: calc(50% - $sds-pax-size-100);

            &:nth-child(8n + 3),
            &:nth-child(8n + 8) {
                width: calc(33% - $sds-pax-size-100);
            }

            &:nth-child(8n + 4),
            &:nth-child(8n + 7) {
                flex-grow: 2;
                width: calc(66% - $sds-pax-size-100);
            }
        }
    }
}

.case {
    background-position: center center;
    background-size: cover;
    color: $sds-pax-color-brand-150;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: $sds-pax-size-620;
    padding: $sds-pax-size-60;
    position: relative;
    text-shadow: 0 0 $sds-pax-size-30 rgba(0, 0, 0, 0.1);
    z-index: 0;

    @media (min-width: 40em) {
        min-height: $sds-pax-size-770;
    }

    :last-child {
        margin-bottom: 0;
    }

    &::before {
        background-image: linear-gradient(
            15deg,
            rgba($sds-pax-color-brand-50, 0.6) 0%,
            rgba($sds-pax-color-brand-50, 0.5) 20%,
            rgba($sds-pax-color-brand-50, 0.1) 60%,
            rgba($sds-pax-color-brand-50, 0)
        );
        bottom: 0;
        content: " ";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    > * {
        z-index: 1;
    }

    .case__title {
        z-index: 2;
    }
}

.case__link {
    border-bottom: 0;
    color: $sds-pax-color-brand-150;

    &::before {
        bottom: 0;
        box-shadow: 0 $sds-pax-size-40 $sds-pax-size-70 0
            rgba($sds-pax-color-brand-50, 0);
        content: " ";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: box-shadow $transition-fast;
        z-index: 2;
    }

    &:focus {
        outline: 0;

        &::before {
            outline: $sds-pax-size-90 solid $sds-pax-color-accent-350;
            outline-offset: 0;
        }
    }

    &:hover {
        background-color: transparent;
        border-bottom: 0;

        &::before {
            box-shadow: 0 $sds-pax-size-40 $sds-pax-size-70 0
                rgba($sds-pax-color-brand-50, 0.2);
        }
    }
}

.case__title {
    margin: 0;

    @media (min-width: 60em) {
        font-size: $sds-pax-size-120;
    }
}
