.contact-block {
    background-color: $sds-pax-color-neutral-150;
    padding: $sds-pax-size-100;

    &--horizontal {
        .contact-block__list {
            flex-direction: row;
        }

        .contact-block__list-item:last-child {
            margin-bottom: $sds-pax-size-50;
        }
    }
}

.contact-block__list {
    @include list-unstyled;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}

.contact-block__list-item {
    align-items: flex-start;
    display: flex;
    flex: 1 0 50%;
    margin-bottom: $sds-pax-size-50;
    padding: 0;

    &:last-child {
        margin-bottom: 0;
    }
}

.rw-icon {
    .contact-block__list-item & {
        color: $sds-pax-color-accent-50;
        flex: none;
        height: $sds-pax-size-90;
        margin-right: $sds-pax-size-70;
        width: $sds-pax-size-90;
    }
}
