.location {
    background: $sds-pax-color-neutral-80;

    @media (min-width: 48em) {
        display: flex;
    }
}

.location__text {
    background: $sds-pax-color-brand-50;
    color: $sds-pax-color-brand-150;
    padding: $sds-pax-size-80 $sds-pax-size-90;

    @media (min-width: 48em) {
        max-width: 19.375rem;
    }
}

.location__title {
    font-size: $sds-pax-font-size-h2-mobile;
    line-height: $sds-pax-line-height-h2;

    @media (min-width: 48em) {
        font-size: $sds-pax-font-size-h2-desktop;
    }
}

.location__subtitle {
    border-top: 1px solid $sds-pax-color-brand-150;
    font-size: $sds-pax-font-size-h3-mobile;
    line-height: $sds-pax-line-height-h3;
    margin-bottom: $sds-pax-size-50;
    margin-top: $sds-pax-size-80;
    padding-top: $sds-pax-size-70;

    @media (min-width: 48em) {
        font-size: $sds-pax-font-size-h3-desktop;
    }
}

.location__link {
    color: $sds-pax-color-parking-50;
}

.location-directions {
    @include list-unstyled;
}

.location-directions__item {
    display: flex;
    margin-bottom: $sds-pax-size-40;
    padding-left: 0;
}

.location-directions__icon {
    flex: 0 0 $sds-pax-size-100;
}

.location__map {
    display: none;

    @media (min-width: 48em) {
        align-items: center;
        display: flex;
        width: 100%;
    }
}

.location__image {
    display: block;
}
