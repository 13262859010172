.rw-contact-list__details {
    @include list-unstyled;
    margin-bottom: 0;
}

.rw-contact-list__detail {
    padding: 0;

    & + & {
        margin: $sds-pax-size-60 0 0;
    }
}

.rw-contact-list__jobtitle {
    font-weight: normal;
    margin-bottom: $sds-pax-size-60;
}

.rw-contact-list__media {
    padding: $sds-pax-size-70 0 0 $sds-pax-size-70;
}

.rw-contact-list__frame {
    border-radius: 50%;
    height: $sds-pax-size-290;
    overflow: hidden;
    width: $sds-pax-size-290;
}

.rw-icon {
    .rw-contact-list__detail & {
        height: $sds-pax-size-80;
        transform: translateY(-#{$sds-pax-size-10});
        vertical-align: middle;
        width: $sds-pax-size-80;
    }
}
