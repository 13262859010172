.privium-contact {
    background-color: $sds-pax-color-neutral-150;
    padding: $sds-pax-size-100;
}

.privium-contact__links {
    @include list-unstyled;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    @media (min-width: 40em) {
        flex-direction: row;
    }
}

.privium-contact__link {
    align-items: center;
    display: flex;
    flex: 1 0 50%;
    margin-bottom: $sds-pax-size-70;
    padding: 0;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 40em) {
        display: flex;
        margin-bottom: 0;
    }
}

.privium-contact__icon {
    color: $sds-pax-color-accent-50;
    margin-right: $sds-pax-size-70;
}

.privium-contact__details-wrapper {
    display: inline;
}
