.company-directory {
    margin: $sds-pax-size-50 0;

    @media (min-width: 48em) {
        column-count: 2;
        margin: $sds-pax-size-70 0;
    }

    @media (min-width: 64em) {
        column-count: 3;
    }
}

.company-directory__h {
    font-size: $sds-pax-size-60;
    font-weight: 900;
    line-height: 1.25;
    margin: 0;
}

.company-directory__group {
    break-inside: avoid;
    display: table;
    margin-bottom: $sds-pax-size-60;
    vertical-align: top;
}
