.rw-chip {
    line-height: 1;
    margin-bottom: $sds-pax-size-60;
}

.hidden {
    display: none;
}

.disabled {
    pointer-events: none;
}

.itinerary__chart-wrapper {
    margin-top: $sds-pax-size-40;
    max-width: $sds-pax-size-1020;
    overflow-x: hidden;
}

.itinerary__chart {
    position: relative;

    &::before,
    &::after {
        background-color: $sds-pax-color-background-primary;
        content: "";
        display: block;
        height: 20%;
        left: 0;
        position: absolute;
        top: 0;
        width: $sds-pax-size-120;
    }

    &::after {
        height: 2px;
        top: 9px;
        width: 100%;
    }
}

.itinerary__chart-info {
    align-items: center;
    background-color: $sds-pax-color-accent-50;
    border-radius: $sds-pax-size-40;
    color: $sds-pax-color-brand-150;
    display: flex;
    display: none;
    font-size: $sds-pax-font-size-text-s;
    margin-right: $sds-pax-size-30;
    padding: $sds-pax-size-30 $sds-pax-size-40;
}

.itinerary__accessible-table {
    @include visually-hidden;
}

.label--waitingtime {
    background-color: $sds-pax-color-accent-150;
    padding-left: $sds-pax-size-90;
}

.label__text {
    display: inline-block;
}

// stylelint-disable-next-line selector-max-id
#itinerary {
    animation-duration: 300ms;
    animation-name: slideInUp;
    animation-timing-function: cubic-bezier(0.72, 0.74, 0.48, 0.96);

    // stylelint-disable-next-line selector-max-id
    &.hide {
        animation-name: slideOutDown;
    }
}

@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translate3d(0, $sds-pax-size-320, 0);
        visibility: hidden;
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
}

@keyframes slideOutDown {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    to {
        opacity: 0;
        transform: translate3d(0, $sds-pax-size-320, 0);
        visibility: hidden;
    }
}
