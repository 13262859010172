.rw-ui-inner {
    &.inner--nested {
        max-width: none;
        padding: 0;
    }
}

.gradient--faq {
    // @TODO - Replace with SDS-token
    background: linear-gradient(
        180deg,
        $sds-pax-color-brand-150 0%,
        #d5dff8 100%
    );
    overflow-x: hidden;
    padding-bottom: $sds-pax-size-410;

    @media (min-width: 48em) {
        padding-bottom: $sds-pax-size-360;
    }
}

.faq-accordion {
    margin-top: $sds-pax-size-50;
    position: relative;
    z-index: 10;

    @media (min-width: 48em) {
        margin-top: $sds-pax-size-70;
    }
}

.faq-images {
    margin: -#{$sds-pax-size-440} 0 0;
    overflow-x: hidden;

    @media (min-width: 48em) {
        margin-top: -#{$sds-pax-size-530};
    }
}

.content-image--faq {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 -#{$sds-pax-size-70};

    @media (min-width: 48em) {
        display: flex;
        margin: auto calc(50% - 50vw);
    }
}

.rw-content-image__item {
    margin-top: #{$sds-pax-size-20};

    .content-image--faq & {
        margin-bottom: 0;
    }

    @media (min-width: 48em) {
        margin-top: -#{$sds-pax-size-10};
    }
}

.rw-content-image__item:first-child {
    position: relative;
    z-index: 1;

    .content-image--faq & {
        max-width: $sds-pax-size-920;

        @media (min-width: 48em) {
            max-width: $sds-pax-size-1020;
        }
    }
}

.rw-content-image__item:last-child {
    .content-image--faq & {
        max-width: $sds-pax-size-430;
        position: absolute;
        right: 0;
        top: $sds-pax-size-160;

        @media (min-width: 48em) {
            max-width: $sds-pax-size-790;
            position: initial;
        }
    }
}

.accordion {
    background: $sds-pax-color-background-primary;
    border-radius: $sds-pax-size-30;
    box-shadow: 0 0 0 rgba(20, 18, 81, 0.1);
    margin-top: $sds-pax-size-30;
    padding: 0 $sds-pax-size-70;

    @media (min-width: 48em) {
        padding: 0 $sds-pax-size-90;
    }
}

.accordion__heading {
    margin: 0 -#{$sds-pax-size-70};
    padding: $sds-pax-size-80 $sds-pax-size-70 $sds-pax-size-70;

    @media (min-width: 48em) {
        margin: 0 -#{$sds-pax-size-90};
        padding: $sds-pax-size-80 $sds-pax-size-90 $sds-pax-size-70;
    }
}

.rw-toggle-visibility {
    .accordion & {
        @media (max-width: 47.9375em) {
            border: 0;
            margin: 0 -#{$sds-pax-size-70};
            position: relative;

            &::after {
                background: $sds-pax-color-neutral-80;
                content: "";
                height: $sds-pax-size-50;
                left: $sds-pax-size-70;
                position: absolute;
                right: $sds-pax-size-70;
                top: 0;
            }
        }
    }
}

.rw-toggle-visibility:last-child {
    .accordion & {
        border-bottom: 0;
    }
}

.rw-toggle-visibility .rw-icon--chevron-down {
    .accordion & {
        margin-right: 0;

        @media (min-width: 48em) {
            margin-right: -#{$sds-pax-size-20};
        }
    }
}
