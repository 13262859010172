// -----------------------------------------------------------------------------
//  Flight statuses
// -----------------------------------------------------------------------------

.flight-status {
    border-radius: $sds-pax-size-30;
    display: inline-block;
    font-size: $sds-pax-size-60;
    font-weight: normal;
    line-height: 1;
    margin-bottom: $sds-pax-size-70;
    margin-top: $sds-pax-size-40;
    padding: $sds-pax-size-30 0;

    .card-flight & {
        font-size: $sds-pax-size-50;
        margin-bottom: $sds-pax-size-40;
        margin-top: $sds-pax-size-40;

        @media (min-width: 48em) {
            margin-top: 0;
        }
    }

    .card-flight &.flight-status--unobtrusive {
        font-size: $sds-pax-size-60;
        margin-bottom: 0;
        margin-top: $sds-pax-size-40;
        padding-bottom: 0;
        padding-top: 0;

        @media (min-width: 48em) {
            margin-top: $sds-pax-size-20;
        }
    }
}

.flight-status--normal,
.flight-status--warning,
.flight-status--suggestion {
    color: $sds-pax-color-brand-150;
    font-weight: bold;
    padding-left: $sds-pax-size-40;
    padding-right: $sds-pax-size-40;
    text-transform: uppercase;
}

.flight-status--normal {
    background: $sds-pax-color-brand-50;
}

.flight-status--warning {
    background: $sds-pax-color-signal-50;
}

.flight-status--suggestion {
    background: $sds-pax-color-wayfinding-50;
    color: $sds-pax-color-wayfinding-150;
}

// -----------------------------------------------------------------------------
// Flights via other destination
// -----------------------------------------------------------------------------

.flight__via {
    display: block;
    font-size: $sds-pax-size-80;
    font-style: normal;
    font-weight: 900;
    line-height: 1.25;
    padding: $sds-pax-size-30 0 $sds-pax-size-40;

    &::first-letter {
        text-transform: uppercase;
    }
}

.flight__stop {
    font-weight: 900;
}

// *****************************************************************************
//
// flight-details.scss
//
// *****************************************************************************

.flight-details,
.flight-details-marketing {
    background-color: $sds-pax-color-neutral-150;
    padding-top: $sds-pax-size-10;
}

.flight-details {
    color: $sds-pax-color-text-primary;
    position: relative;

    &:not(.flight-details--cancelled)::before {
        border-left: $sds-pax-size-70 solid transparent;
        border-right: $sds-pax-size-70 solid transparent;
        border-top: $sds-pax-size-70 solid $sds-pax-color-neutral-150;
        content: "";
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        z-index: 1;
    }

    @media (min-width: 40em) {
        padding-top: $sds-pax-size-40;
    }

    @media print {
        &:not(.flight-details--cancelled)::before {
            content: none;
        }
    }
}

// -----------------------------------------------------------------------------
// Flightdetails heading
// -----------------------------------------------------------------------------

.flight-details__info {
    font-size: $sds-pax-size-60;
    margin-bottom: $sds-pax-size-100;
    margin-top: 0;
}

// -----------------------------------------------------------------------------
// Flight information
// -----------------------------------------------------------------------------

.flight-information-title {
    margin-top: 0;
}

.flight-information {
    color: $sds-pax-color-text-primary;
    display: grid;
    font-size: $sds-pax-size-60;
    font-weight: normal;
    grid-gap: $sds-pax-size-60 $sds-pax-size-100;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    margin: 0 0 $sds-pax-size-100;
    padding: 0;
    position: relative;
    z-index: 2;

    .itinerary-v2 & {
        grid-gap: $sds-pax-size-70 $sds-pax-size-60;
        grid-template-areas: "date time time"
            "terminal check-in gate";
        grid-template-columns: auto;
        margin-bottom: $sds-pax-size-60;

        @media (min-width: 48em) {
            grid-template-areas: none;
            grid-template-columns: repeat(5, min-content);
        }
    }

    .flight-details__h + & {
        margin-top: $sds-pax-size-100;

        @media (min-width: 48em) {
            margin-top: $sds-pax-size-100;
        }
    }

    .javascript & {
        margin-bottom: $sds-pax-size-100;
    }

    @media (min-width: 48em) {
        grid-gap: 0;
        grid-template-columns: repeat(6, min-content);
    }

    @media (min-width: 64em) {
        font-size: $sds-pax-size-70;
    }

    // Flight information in the flight list
    &.flight-information--flight-card,
    &.flight-information--flight-card-time {
        display: block;
        margin: 0;
        z-index: 0;
    }

    @media print {
        display: flex;
    }
}

.flight-information__item {
    border-bottom: $sds-pax-size-10 dashed $sds-pax-color-neutral-70;
    margin: 0;
    padding-bottom: $sds-pax-size-60;

    .itinerary-v2 & {
        border-bottom: 0;
        padding-bottom: 0;
    }

    @media (min-width: 48em) {
        border-bottom: 0;
        flex: none;
        padding-bottom: $sds-pax-size-30;
        padding-right: $sds-pax-size-80;

        &:not(:first-child) {
            border-left: $sds-pax-size-10 dashed $sds-pax-color-neutral-70;
            padding-left: $sds-pax-size-80;

            .itinerary-v2 & {
                border-left: 0;
            }
        }
    }

    @media (min-width: 64em) {
        padding-right: $sds-pax-size-100;

        &:not(:first-child) {
            padding-left: $sds-pax-size-100;
        }
    }

    .card-flight & {
        border-bottom: 0;
        flex: auto;
        margin-bottom: $sds-pax-size-40;
        padding-bottom: 0;

        @media (min-width: 48em) {
            border-left: $sds-pax-size-10 dashed $sds-pax-color-neutral-70;
            flex: 1;
            padding: $sds-pax-size-40 $sds-pax-size-70 0;
            width: 33%;
        }
    }

    @media print {
        border: 0;
        padding-right: $sds-pax-size-100;
    }
}

.flight-information__item--flight-number {
    white-space: nowrap;
}

.flight-information__item--date {
    .itinerary-v2 & {
        grid-area: date;

        @media (min-width: 48em) {
            grid-area: auto;
        }
    }
}

.flight-information__item--time {
    .itinerary-v2 & {
        grid-area: time;

        @media (min-width: 48em) {
            grid-area: auto;
        }
    }
}

.flight-information__item--terminal {
    .itinerary-v2 & {
        grid-area: terminal;

        @media (min-width: 48em) {
            grid-area: auto;
        }
    }
}

.flight-information__item--check-in {
    .itinerary-v2 & {
        grid-area: check-in;

        @media (min-width: 48em) {
            grid-area: auto;
        }
    }
}

.flight-information__item--gate {
    @media (max-width: 47.9375em) {
        grid-column-end: 3;
        grid-column-start: 2;
        grid-row-end: 3;
        grid-row-start: 2;
    }

    .flight-information--arrival & {
        @media (min-width: 48em) {
            grid-column-end: 5;
            grid-column-start: 4;
            grid-row-end: 2;
            grid-row-start: 1;
        }
    }

    .itinerary-v2 & {
        grid-area: gate;

        @media (min-width: 48em) {
            grid-area: auto;
        }
    }
}

.flight-information__item--flight-status {
    border: 0;
    grid-area: flight-status;
    margin-bottom: $sds-pax-size-60;
    padding: 0;

    @media (min-width: 48em) {
        margin-bottom: 0;
    }
}

.flight-information__h {
    display: block;
    font-size: $sds-pax-size-60;
    font-weight: normal;
    line-height: 1.7143;
    margin-bottom: $sds-pax-size-40;
    overflow: hidden;
    white-space: nowrap;

    .itinerary-v2 & {
        color: $sds-pax-color-brand-50;
        line-height: $sds-pax-line-height-text;
    }

    .flight-information--flight-card & {
        margin-bottom: 0;

        @media (min-width: 48em) {
            @include visually-hidden;
        }
    }

    .flight-information__item--flight-status & {
        @include visually-hidden;
    }
}

.flight-information__h--gate {
    margin-bottom: $sds-pax-size-10;

    .itinerary-v2 & {
        margin-bottom: 0;
    }
}

.flight-information__description {
    display: block;
    font-size: $sds-pax-size-70;
    font-weight: 900;
    line-height: 1.2222;
    margin: 0;
    padding-right: $sds-pax-size-40;
    position: relative;
    white-space: nowrap;

    .itinerary-v2 & {
        font-size: $sds-pax-size-60;
        font-weight: 700;
        line-height: $sds-pax-line-height-text;

        &.flight-information__description--gate {
            padding-top: $sds-pax-size-30;
        }
    }

    .flight-information--flight-card & {
        font-size: $sds-pax-size-60;
        line-height: 1.25;

        @media (max-width: 47.9375em) {
            display: inline-block;
        }
    }

    .flight-information__item--flight-status & {
        font-weight: 400;
    }
}

// Flight information time notation

.time-early,
.time-delayed {
    display: block;
    text-decoration: none;
    white-space: nowrap;
}

.time-delayed {
    color: $sds-pax-color-signal-50;
}

.time-scheduled {
    display: block;
    font-size: $sds-pax-size-60;
    text-decoration: none;
    white-space: nowrap;

    @media (min-width: 48em) {
        position: absolute;
        top: 100%;

        .card-flight & {
            margin-top: 0;
            position: relative;
            top: auto;
        }

        .itinerary-v2 & {
            position: relative;
            top: auto;
        }
    }
}

time {
    position: relative;

    .time-scheduled & {
        &::before {
            background: $sds-pax-color-signal-50;
            content: "";
            display: block;
            height: $sds-pax-size-20;
            left: 0;
            outline: 0.5px solid $sds-pax-color-brand-150;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

// Gates
.gate {
    display: inline-block;
    position: relative;
    text-decoration: none;
}

.gate__number {
    background: $sds-pax-color-wayfinding-50;
    color: $sds-pax-color-wayfinding-150;
    display: inline-block;
    line-height: 1;
    margin-bottom: $sds-pax-size-10;
    padding: $sds-pax-size-40;
    position: relative;

    .gate--changed & {
        background-color: $sds-pax-color-signal-50;
        color: $sds-pax-color-brand-150;
    }

    .gate--unknown & {
        background-color: transparent;
        color: inherit;
        color: $sds-pax-color-text-primary;
        padding-left: 0;
        padding-right: 0;
    }

    .itinerary-v2 & {
        align-items: center;
        display: inline-flex;
        min-height: $sds-pax-size-100;
        padding: $sds-pax-size-30 $sds-pax-size-40;
    }
}

.gate__number--radius-medium {
    border-radius: $sds-pax-size-30;
}

.gate__number--radius-large {
    border-radius: $sds-pax-size-40;
}

.rw-icon {
    .gate__number & {
        height: $sds-pax-size-70;
        margin-right: $sds-pax-size-30;
        width: $sds-pax-size-70;
    }

    .download-app-links & {
        display: block;
        height: $sds-pax-size-110;
        width: auto;
    }

    .flight-updates-title & {
        height: $sds-pax-size-80;
        margin-right: $sds-pax-size-50;
        position: relative;
        vertical-align: middle;
        width: $sds-pax-size-80;

        .flight-updates--gate_changed &,
        .flight-updates--cancelled &,
        .flight-updates--delayed &,
        .flight-updates--diverted & {
            color: inherit;
        }

        .flight-updates--cancelled & {
            width: $sds-pax-size-80;
        }
    }

    .preparation-tips__heading & {
        color: $sds-pax-color-accent-50;
        left: 0;
        position: absolute;
        top: -$sds-pax-size-20;
    }

    .itinerary-steps-toggle__button & {
        color: $sds-pax-color-neutral-70;
        margin-right: 0.5rem;
    }
}

.gate__message {
    color: $sds-pax-color-signal-50;
    display: block;
    font-size: $sds-pax-size-60;
    margin-top: $sds-pax-size-30;
    white-space: nowrap;

    @media (min-width: 48em) {
        position: absolute;
        top: 100%;
    }
}

// -----------------------------------------------------------------------------
// Flight details columns
// -----------------------------------------------------------------------------

.flight-details-columns {
    margin-top: $sds-pax-size-80;

    @media (min-width: 48em) {
        display: flex;

        .itinerary-v2 & {
            display: block;
        }
    }
}

.flight-details-itinerary {
    @media (min-width: 48em) {
        margin-right: $sds-pax-size-100;
        max-width: 41.125rem;

        .itinerary-v2 & {
            max-width: none;
            width: 100%;
        }
    }
}

.flight-details-itinerary__header {
    margin-top: 0;

    .itinerary-v2 & {
        font-size: $sds-pax-font-size-text-l;
        line-height: $sds-pax-line-height-h3;
        margin-bottom: $sds-pax-size-40;
        margin-top: $sds-pax-size-100;

        @media (min-width: 48em) {
            font-size: $sds-pax-font-size-h3-desktop;
            margin-top: $sds-pax-size-170;
        }
    }
}

.flight-details-itinerary__tagline {
    font-size: $sds-pax-font-size-text-s;
    line-height: $sds-pax-line-height-text;
    margin-bottom: $sds-pax-size-30;

    @media (min-width: 48em) {
        font-size: $sds-pax-font-size-text-m;
    }
}

.flight-details-information {
    padding-bottom: $sds-pax-size-100;
    padding-top: $sds-pax-size-100;
    position: relative;
    z-index: 1;

    @media (min-width: 48em) {
        margin-left: auto;
        padding-bottom: $sds-pax-size-100;
        padding-top: 0;
        width: $sds-pax-size-790;
    }

    .itinerary-v2 & {
        margin-left: 0;
        padding-top: $sds-pax-size-60;

        @media (min-width: 48em) {
            width: 100%;
        }
    }
}

.flight-details-information__body {
    margin-top: $sds-pax-size-70;
}

// -----------------------------------------------------------------------------
// Itinerary
// -----------------------------------------------------------------------------

.itinerary {
    color: $sds-pax-color-text-primary;
    list-style-type: none;
    margin: $sds-pax-size-60 0;
    padding: 0;

    @media (min-width: 64em) {
        margin: $sds-pax-size-80 0 1.125em;
    }
}

.itinerary__item {
    padding: 0 0 $sds-pax-size-100 $sds-pax-size-100;
    position: relative;

    @media (min-width: 64em) {
        padding-bottom: $sds-pax-size-90;
        padding-left: $sds-pax-size-160;
    }

    .itinerary-v2 & {
        font-size: $sds-pax-font-size-text-s;
        padding-bottom: $sds-pax-size-60;
        padding-left: $sds-pax-size-110;

        @media (min-width: 48em) {
            font-size: $sds-pax-font-size-text-m;
            padding-bottom: $sds-pax-size-80;
        }

        @media (min-width: 64em) {
            padding-bottom: $sds-pax-size-80;
            padding-left: $sds-pax-size-110;
        }

        &.itinerary__item--walking-time {
            padding-bottom: $sds-pax-size-80;

            @media (min-width: 64em) {
                padding-bottom: $sds-pax-size-80;
            }
        }

        &[data-step-has-passed="true"] {
            &::after {
                background: transparent url($background-icons-path + "/rw-checkmark-itinerary--grey.svg") no-repeat 0 0;
                box-shadow: none;
                height: 1.5rem;
                left: -0.125rem;
                top: 0.8125rem;
                width: 1.5rem;
            }
        }

        &.inactive {
            pointer-events: none;

            &::after {
                opacity: 0.5;
            }
        }
    }

    // Last item no spacing
    &:last-child {
        @media (max-width: 64em) {
            padding-bottom: 0;
        }
    }

    &:not(:last-child)::before {
        border-left: $sds-pax-size-10 dashed $sds-pax-color-neutral-70;
        bottom: -#{$sds-pax-size-30};
        content: "";
        left: $sds-pax-size-40;
        position: absolute;
        top: $sds-pax-size-40;

        @media (min-width: 64em) {
            left: $sds-pax-size-50;
        }

        .itinerary-v2 & {
            background: $sds-pax-color-neutral-70;
            border: 0;
            border-radius: $sds-pax-size-50;
            bottom: $sds-pax-size-40;
            left: 10px;
            top: 43px;
            transform: translateX(-$sds-pax-size-10);
            width: $sds-pax-size-20;
        }
    }

    &::after {
        background: radial-gradient(
                ellipse at center,
                $sds-pax-color-background-primary $sds-pax-size-30,
                transparent $sds-pax-size-30
            ),
            // @TODO - Replace with SDS-token
            linear-gradient(135deg, #a35bcd 0%, $sds-pax-color-accent-50 100%);
        border-radius: 100%;
        content: "";
        display: block;
        height: $sds-pax-size-60;
        left: 0;
        position: absolute;
        top: $sds-pax-size-20;
        width: $sds-pax-size-60;

        @media (min-width: 64em) {
            background: radial-gradient(
                    ellipse at center,
                    $sds-pax-color-background-primary $sds-pax-size-30,
                    transparent $sds-pax-size-30
                ),
                // @TODO - Replace with SDS-token
                linear-gradient(
                        135deg,
                        #a35bcd 0%,
                        $sds-pax-color-accent-50 100%
                    );
            height: $sds-pax-size-80;
            left: 0;
            top: 0;
            width: $sds-pax-size-80;
        }

        .itinerary-v2 & {
            background: transparent;
            border-radius: 50%;
            box-shadow: 0 0 0 6px $sds-pax-color-accent-350;
            height: $sds-pax-size-40;
            left: 0.375rem;
            top: 1.3125rem;
            width: $sds-pax-size-40;
        }
    }

    &.itinerary__item--steps-toggle {
        &::after {
            box-shadow: none;
            height: 1.5rem;
            left: -0.125rem;
            top: 0.8125rem;
            width: 1.5rem;
        }
    }

    &.itinerary__item--walking-time {
        &:not(:last-child)::before {
            top: $sds-pax-size-100;
        }

        &::after {
            background: transparent
                url($background-icons-path + "/rw-walk--grey.svg") no-repeat 0 0;
            background-size: cover;
            box-shadow: none;
            height: $sds-pax-size-100;
            left: -$sds-pax-size-40;
            top: -$sds-pax-size-40;
            width: $sds-pax-size-100;
        }
    }

    &.itinerary__item--time-to-relax {
        &:not(:last-child)::before {
            top: 37px;
        }

        &::after {
            box-shadow: 0 0 0 $sds-pax-size-20 $sds-pax-color-neutral-70;
            height: $sds-pax-size-60;
            left: 2px;
            top: 20px;
            width: $sds-pax-size-60;
        }
    }

    &.itinerary__item--walking-time + .itinerary__item {
        & .itinerary__container {
            &::before {
                top: -19px;
            }
        }
    }
}

// Delayed flight has a different dot color
.itinerary__item--delay {
    &::after {
        background: radial-gradient(
            ellipse at center,
            $sds-pax-color-brand-150 $sds-pax-size-30,
            $sds-pax-color-signal-50 $sds-pax-size-30
        );

        @media (min-width: 64em) {
            background: radial-gradient(
                ellipse at center,
                $sds-pax-color-brand-150 $sds-pax-size-30,
                $sds-pax-color-signal-50 $sds-pax-size-30
            );
        }
    }
}

.itinerary__container {
    background-color: $sds-pax-color-brand-150;
    border-radius: $sds-pax-size-40;
    padding: $sds-pax-size-60;

    &::before {
        background: $sds-pax-color-neutral-70;
        border: 0;
        border-radius: $sds-pax-size-50;
        content: "";
        height: $sds-pax-size-90;
        left: 10px;
        position: absolute;
        top: -21px;
        transform: translateX(-$sds-pax-size-10);
        width: $sds-pax-size-20;

        .itinerary__item:first-child & {
            content: none;
        }

        .itinerary__item[data-step-has-passed="true"]:first-child & {
            content: "";
        }

        .itinerary__item--walking-time & {
            content: none;
        }

        .itinerary__item--time-to-relax & {
            top: -9px;
        }
    }

    .itinerary__item--walking-time & {
        background: transparent;
        padding: 0;
    }

    .itinerary__item--steps-toggle & {
        background: transparent;
        padding-bottom: 0;
        padding-left: 0;
    }
}

.itinerary__h {
    .itinerary__header & {
        margin-bottom: 0;
    }

    .itinerary-v2 & {
        display: flex;
        font-size: $sds-pax-size-60;
        justify-content: space-between;
        width: 100%;
    }

    .itinerary__item--steps-toggle & {
        font-weight: $sds-pax-font-weight-text;
    }

    .itinerary__item--walking-time & {
        display: block;
        font-weight: $sds-pax-font-weight-text;
    }

    .itinerary__item--delay & {
        color: $sds-pax-color-signal-50;
        margin-bottom: 0;

        .itinerary-v2 & {
            color: inherit;
        }
    }
}

.inactive {
    .itinerary__h & {
        text-decoration: line-through;
    }
}

.trigger-a11y {
    .itinerary__h & {
        background-position: right -#{$sds-pax-size-20};

        .itinerary__item.inactive & {
            background-image: none;
        }
    }

    .itinerary__h &,
    .rw-toggle__trigger & {
        background-image: url($background-icons-path + "/rw-chevron-down--schipholblue.svg");

        &:focus-visible {
            border-radius: $sds-pax-size-20;
            outline: $sds-pax-size-20 dotted $sds-pax-color-accent-350;
            outline-offset: $sds-pax-size-30;
        }
    }

    .itinerary__h[aria-expanded="true"] &,
    .rw-toggle__trigger[aria-expanded="true"] & {
        background-image: url($background-icons-path + "/rw-chevron-up--schipholblue.svg");
    }
}

.itinerary__h-time {
    font-weight: $sds-pax-font-weight-text;
    padding: 0 0 0 $sds-pax-size-70;
    pointer-events: none;

    .trigger-a11y & {
        padding-right: $sds-pax-size-60;
    }
}

.itinerary__header-content {
    margin: $sds-pax-size-40 0 0 0;

    &.itinerary__header-content--inactive {
        .inactive & {
            display: block;
        }
    }

    &:not(.itinerary__header-content--inactive) {
        .inactive & {
            display: none;
        }
    }

    &[data-crowd="normal"] use:nth-of-type(1n + 3) {
        fill: $sds-pax-color-accent-350;
    }

    &[data-crowd="busy"] use:nth-of-type(1n + 2) {
        fill: $sds-pax-color-accent-350;
    }

    &[data-crowd="peak"] use:nth-of-type(1n + 1) {
        fill: $sds-pax-color-accent-350;
    }
}

p {
    .itinerary__header-content & {
        margin-bottom: $sds-pax-size-0;
    }

    .download-app-notification__text & {
        margin-bottom: 0;
    }
}

.schiphol-security-crowd__icon {
    display: inline-block;
    transform: translateY(-$sds-pax-size-20);
    vertical-align: middle;
}

.itinerary__subheading {
    margin-bottom: 0;
}

.itinerary__time-expected {
    text-decoration: none;
}

.itinerary__details {
    font-size: $sds-pax-size-60;
    list-style-type: none;
    margin: 0.75em 0 0;
    padding: 0;

    li {
        margin-left: 0;
        padding-left: 0;
    }

    .itinerary__details-item {
        margin: 0 0 $sds-pax-size-40;

        .itinerary-v2 & {
            margin: $sds-pax-size-40 0 0 0;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .itinerary__details-item--stacked {
        // display: inline-block;
        margin: 0 0 $sds-pax-size-60;
    }

    .itinerary-v2 & {
        font-size: inherit;
        margin: 0;
    }
}

.itinerary__via {
    display: block;
    font-style: normal;
    font-weight: normal;

    &::first-letter {
        text-transform: uppercase;
    }
}

.itinerary__status {
    display: block;
}

.itinerary__attention {
    color: $sds-pax-color-signal-50;
}

.itinerary__time-schema {
    font-size: $sds-pax-size-60;
    font-weight: bold;
    line-height: 1.375;
    margin: 0 0 $sds-pax-size-50;
}

// -----------------------------------------------------------------------------
// Extra information
// -----------------------------------------------------------------------------

.flight-details-information__tracker {
    margin-bottom: $sds-pax-size-50;
}

.rw-info-block {
    .flight-details-information & {
        & > *:last-child {
            margin-bottom: 0;
        }
    }

    .itinerary-v2 & {
        background: transparent;
        padding: 0;
    }
}

.rw-info-block__container {
    background: $sds-pax-color-brand-150;
    border-radius: $sds-pax-size-40;
    padding: $sds-pax-size-60;
}

.rw-info-block + .rw-info-block {
    .flight-details-information & {
        margin-top: $sds-pax-size-50;
    }

    .itinerary-v2 & {
        margin-top: $sds-pax-size-100;

        @media (min-width: 48em) {
            margin-top: $sds-pax-size-170;
        }
    }
}

.rw-info-block__header {
    .itinerary-v2 & {
        margin-bottom: $sds-pax-size-60;
    }
}

.rw-info-block__subject {
    .flight-details-information & {
        margin-top: $sds-pax-size-70;
    }
}

.aircraft-details {
    font-size: $sds-pax-size-60;
    margin-bottom: $sds-pax-size-50;

    .itinerary-v2 & {
        margin-bottom: 0;
    }
}

.aircraft-details__title {
    font-weight: 900;
    line-height: 1.25;
    margin: $sds-pax-size-70 0 $sds-pax-size-40;

    .itinerary-v2 & {
        color: $sds-pax-color-neutral-50;
        font-size: $sds-pax-font-size-text-s;
        font-weight: $sds-pax-font-weight-text;
        line-height: $sds-pax-line-height-text;
        margin: 0;

        &:not(:first-of-type) {
            border-top: $sds-pax-size-10 solid $sds-pax-color-neutral-150;
            margin: $sds-pax-size-40 0 0 0;
            padding-top: $sds-pax-size-40;
        }
    }
}

.aircraft-details__description {
    margin: 0;

    .itinerary-v2 & {
        display: inline;

        &.aircraft-details__description--codeshares::after {
            content: ", ";
        }

        &.aircraft-details__description--codeshares:last-child::after {
            content: "";
        }
    }
}

// -----------------------------------------------------------------------------
// Waiting times
// -----------------------------------------------------------------------------

.waiting-times {
    background: $sds-pax-color-background-primary;
    border-radius: $sds-pax-size-30;
    color: $sds-pax-color-text-primary;
    display: inline-block;
    font-size: $sds-pax-size-60;
    line-height: 1;
    margin-left: $sds-pax-size-50;
    padding: $sds-pax-size-40 $sds-pax-size-40 $sds-pax-size-30
        $sds-pax-size-110;
    position: relative;
}

.rw-icon--clock {
    .waiting-times & {
        height: auto;
        left: $sds-pax-size-30;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: $sds-pax-size-80;
    }
}

.waiting-time-label {
    margin-right: $sds-pax-size-30;
}

.waiting-time-label ~ .waiting-times {
    margin-left: 0;
}

.waiting-time-tooltip {
    display: inline-block;
    margin-left: $sds-pax-size-40;
}

.predicted-waiting-times {
    color: $sds-pax-color-accent-50;
    font-weight: $sds-pax-font-weight-text-bold;
}

.list-pois {
    font-size: $sds-pax-size-60;
    list-style-type: none;
    margin: 0.75em 0 0;
    padding: 0;
}

.list-pois__item {
    display: inline-block;
    margin-left: 0;
    margin-right: $sds-pax-size-40;
    padding-left: 0;
}

.list-pois__button {
    background: $sds-pax-color-background-primary;
    border-radius: $sds-pax-size-40;
    max-height: $sds-pax-size-120;
    max-width: $sds-pax-size-120;
    padding: $sds-pax-size-30;
}

// -----------------------------------------------------------------------------
// Waiting times disclaimer
// -----------------------------------------------------------------------------

.waiting-times-disclaimer {
    font-size: $sds-pax-size-60;
    padding-left: $sds-pax-size-100;

    @media (min-width: 64em) {
        font-size: $sds-pax-size-60;
        margin-bottom: $sds-pax-size-240;
        padding-left: $sds-pax-size-160;
    }
}

.security-forecast-disclaimer {
    color: $sds-pax-color-neutral-50;
    font-weight: 900;
    line-height: 1.25;
    margin: $sds-pax-size-70 0;

    a {
        color: inherit;
    }

    .itinerary-v2 & {
        color: $sds-pax-color-neutral-50;
        font-size: $sds-pax-font-size-text-s;
        font-weight: $sds-pax-font-weight-text;
        line-height: $sds-pax-line-height-text;
        margin: $sds-pax-size-60 0 0;

        &:not(:first-of-type) {
            border-top: $sds-pax-size-10 solid $sds-pax-color-neutral-150;
            margin: $sds-pax-size-40 0 0 0;
            padding-top: $sds-pax-size-40;
        }
    }
}

// -----------------------------------------------------------------------------
// Tooltip flight info
// -----------------------------------------------------------------------------

.flight-info-tooltip {
    font-weight: normal;
}

.flight-info-tooltip__list {
    font-size: $sds-pax-size-60;
    margin: 0;

    .rw-tooltip__bubble & {
        display: flex;
        flex-wrap: wrap;
        margin-right: -#{$sds-pax-size-70};
    }
}

.flight-info-tooltip__body {
    margin: 0 $sds-pax-size-60 0 0;
}

.flight-info-tooltip__h {
    margin: 0;

    .rw-tooltip__bubble & {
        margin-bottom: $sds-pax-size-50;
    }
}

.flight-info-tooltip__airline,
.flight-info-tooltip__number {
    display: inline;

    .rw-tooltip__bubble & {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.flight-info-tooltip__airline {
    display: inline;
    font-weight: bold;

    .rw-tooltip__bubble & {
        flex-basis: 74%;
    }
}

.flight-info-tooltip__number {
    margin: 0;

    &::after {
        content: ", ";
    }

    &:last-child::after {
        content: "";
    }

    .rw-tooltip__bubble & {
        flex-basis: 26%;
        text-align: right;

        &::after {
            content: "";
        }
    }
}

// -----------------------------------------------------------------------------
// Download app
// -----------------------------------------------------------------------------

.download-app {
    background: $sds-pax-color-brand-150;
    border-radius: $sds-pax-size-40;
    color: $sds-pax-color-text-primary;
    margin-bottom: $sds-pax-size-230;
    padding: $sds-pax-size-80 $sds-pax-size-70 $sds-pax-size-60;
    position: relative;

    @media (min-width: 48em) {
        display: flex;
        flex-wrap: wrap;
        padding: $sds-pax-size-130 $sds-pax-size-120 $sds-pax-size-110;
    }
}

.download-app__body {
    max-width: $sds-pax-size-920;
}

.download-app__h {
    font-size: $sds-pax-size-80;
    line-height: 1.3182;
    margin: 0 0 $sds-pax-size-60;

    @media (min-width: 48em) {
        font-size: $sds-pax-size-90;
        line-height: 1.1429;
        margin: 0 0 $sds-pax-size-90;
    }
}

.download-app__text {
    margin: 0;
}

.download-app-links {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: $sds-pax-size-50 0 0;

    @media (min-width: 20.625em) {
        justify-content: flex-start;
    }

    @media (min-width: 48em) {
        padding-top: $sds-pax-size-40;
    }
}

.download-app-links__item {
    display: inline-block;
    padding-left: 0;

    & + & {
        @media (min-width: 20.625em) {
            margin-left: $sds-pax-size-50;
        }

        @media (min-width: 48em) {
            margin-left: $sds-pax-size-80;
        }
    }
}

.download-app-notification {
    background: $sds-pax-color-background-primary;
    border-radius: $sds-pax-size-60;
    box-shadow: rgba(0, 0, 0, 0.16) 0 $sds-pax-size-40 $sds-pax-size-110 0;
    margin-bottom: $sds-pax-size-40;
    padding: $sds-pax-size-50;

    @media (min-width: 48em) {
        margin-bottom: $sds-pax-size-110;
    }
}

.download-app-notification__header {
    display: flex;
    margin-bottom: $sds-pax-size-10;

    @media (min-width: 48em) {
        margin-bottom: $sds-pax-size-10;
    }
}

.download-app__icon {
    border-radius: $sds-pax-size-30;
}

.download-app-notification__schiphol {
    color: $sds-pax-color-neutral-50;
    flex: 1;
    font-size: $sds-pax-font-size-text-s;
    font-weight: 400;
    line-height: 1.8462;
    padding-left: $sds-pax-size-40;
}

.download-app-notification__time {
    color: $sds-pax-color-neutral-50;
    font-size: $sds-pax-font-size-text-s;
    font-weight: 400;
    line-height: 1.8462;
    margin: 0;
    padding-right: $sds-pax-size-40;
}

.download-app-notification__body {
    font-size: $sds-pax-size-60;
    padding: 0 $sds-pax-size-20;
}

.download-app-notification__title {
    font-size: $sds-pax-size-60;
    font-weight: 700;
    line-height: 1.3333;
    margin-bottom: $sds-pax-size-40;

    @media (min-width: 48em) {
        margin-bottom: $sds-pax-size-20;
    }
}

.download-app-notification__text {
    line-height: 1.2;
    margin-bottom: 0;
}

.download-app__media {
    display: none;

    @media (min-width: 48em) {
        display: block;
        flex: 1;
        position: relative;
        text-align: right;
    }
}

.download-app__image {
    display: block;

    @media (min-width: 48em) {
        position: absolute;
        right: -#{$sds-pax-size-110};
        top: 50%;
        transform: translateY(-50%);
    }

    @media (min-width: 58.75em) {
        top: -#{$sds-pax-size-160};
        transform: none;
    }

    @media (min-width: 64em) {
        width: 31.8125rem;
    }
}

.download-app-links__link {
    background: transparent no-repeat 0 0;
    background-size: auto 100%;
    border-bottom: 0;
    display: block;
    overflow: hidden;

    &:hover {
        background-color: transparent;
    }
}

// Itinerary tip
.itinerary-tip {
    background: $sds-pax-color-background-primary;
    padding: $sds-pax-size-60;

    .itinerary__details li & {
        margin-bottom: $sds-pax-size-50;
    }

    .itinerary__details > & {
        margin-top: $sds-pax-size-60;
    }

    .itinerary-v2 & {
        margin-top: $sds-pax-size-60;
        padding: 0;
    }
}

.itinerary-header {
    .itinerary-v2 & {
        font-size: $sds-pax-font-size-text-s;
        line-height: $sds-pax-line-height-text;

        @media (min-width: 48em) {
            font-size: $sds-pax-font-size-text-m;
        }
    }
}

.itinerary-tip__body {
    .itinerary-v2 & {
        font-size: $sds-pax-font-size-text-s;
        line-height: $sds-pax-line-height-text;

        @media (min-width: 48em) {
            font-size: $sds-pax-font-size-text-m;
        }
    }
}

p:last-child {
    .itinerary-tip__body & {
        margin: 0;
    }
}

// -----------------------------------------------------------------------------
// Card flight
// -----------------------------------------------------------------------------

.card-flight {
    background-color: $sds-pax-color-brand-150;
    box-shadow: 0 0.0125em $sds-pax-size-50 0 rgba($sds-pax-color-brand-50, 0.1);
    margin-bottom: $sds-pax-size-40;
    padding: $sds-pax-size-70 $sds-pax-size-70 $sds-pax-size-60;
    position: relative;
    z-index: 0;

    @media (min-width: 40em) {
        padding-bottom: $sds-pax-size-70;
    }
}

.card-flight__link {
    border: 0;
    color: $sds-pax-color-text-primary;
    display: flex;
    flex-direction: column;
    line-height: 1.25;

    &:hover::before {
        box-shadow: 0 $sds-pax-size-20 $sds-pax-size-70 0
            rgba($sds-pax-color-brand-50, 0.2);
    }

    &:focus {
        outline: 0;
    }

    &:focus::before {
        outline: $sds-pax-size-20 solid $sds-pax-color-accent-350;
    }

    &:hover {
        background: transparent;
        outline: 0;
    }
}

.card-flight__number,
.card-flight__airport,
.card-flight__via,
.card-flight .rw-icon-link__text {
    align-self: flex-start;
    border-bottom: $sds-pax-size-10 solid transparent;
    transition: border-bottom-color $transition-fast;

    .card-flight__link:focus &,
    .card-flight__link:hover & {
        border-bottom-color: inherit;
    }
}

.card-flight__number {
    font-size: $sds-pax-size-60;
    margin-top: $sds-pax-size-10;

    @media (min-width: 40em) {
        margin-top: $sds-pax-size-40;
    }
}

.card-flight__airport {
    display: block;
    order: -1;
}

.card-flight__call-to-action {
    position: absolute;
    right: $sds-pax-size-60;
    top: $sds-pax-size-60;

    @media (min-width: 40em) {
        right: $sds-pax-size-70;
        top: $sds-pax-size-80;
    }
}

.rw-icon-link__text {
    .card-flight__call-to-action & {
        display: none;

        @media (min-width: 48.0625em) {
            display: inline-block;
        }
    }

    .itinerary-v2 .flights__last-updated & {
        font-size: $sds-pax-font-size-text-s;
    }
}

.card-flight__code-shares {
    border-top: $sds-pax-size-10 dashed $sds-pax-color-neutral-70;
    font-size: $sds-pax-size-60;
    line-height: 1.4286;
    margin: $sds-pax-size-40 0 0;
    padding-bottom: $sds-pax-size-10;
    padding-top: $sds-pax-size-60;

    @media (min-width: 48em) {
        margin-top: $sds-pax-size-70;
        width: 100%;
    }
}

.card-flight__code-share {
    &:not(:last-child)::after {
        content: "/";
    }
}

// -----------------------------------------------------------------------------
// Flight definitions
// -----------------------------------------------------------------------------

.flight-definition {
    color: $sds-pax-color-text-primary;
    display: flex;
    flex-direction: column;
    font-size: $sds-pax-size-60;
    font-weight: normal;
    line-height: 1.25;
    list-style: none;
    margin: 0;
    padding: 0 0 0 $sds-pax-size-170;

    @media (min-width: 48em) {
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 0;
    }

    @media (min-width: 64em) {
        font-size: $sds-pax-size-70;
    }

    @media print {
        display: flex;
    }
}

.flight-definition__h {
    @include visually-hidden;
    display: block;
    font-size: $sds-pax-size-60;
    font-weight: normal;
    line-height: 1.7143;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
}

.flight-definition__description {
    display: block;
    font-size: $sds-pax-size-70;
    font-size: $sds-pax-size-60;
    line-height: 1.25;
    margin: 0;
    padding-right: $sds-pax-size-40;
    white-space: nowrap;

    @media (max-width: 47.9375em) {
        display: inline-block;
    }
}

.flight-definition__item {
    @media (min-width: 48em) {
        padding-top: $sds-pax-size-40;
    }
}

.flight-definition__item--destination {
    margin: 0;

    @media (min-width: 48em) {
        border: $sds-pax-size-10 dashed $sds-pax-color-neutral-70;
        border-width: 0 $sds-pax-size-10;
        margin-right: $sds-pax-size-70;
        padding: $sds-pax-size-40 $sds-pax-size-70 0;
        width: 37%;
    }
}

.flight-definition__item--time {
    font-weight: 900;
    left: $sds-pax-size-70;
    order: -1;
    position: absolute;
    width: $sds-pax-size-170;

    @media (min-width: 48em) {
        display: flex;
        left: auto;
        margin-bottom: $sds-pax-size-40;
        position: relative;
        width: 17%;
    }
}

.flight-definition__item--flight-status {
    margin-bottom: $sds-pax-size-20;
}

// -----------------------------------------------------------------------------
// SMS Notification button
// -----------------------------------------------------------------------------

.header-flight-details__flight-updates {
    margin-bottom: $sds-pax-size-120;
}

// V2 Itinerary
.itinerary-v2 {
    background: $sds-pax-color-neutral-150;
}

.header-flight-details {
    .itinerary-v2 & {
        padding-bottom: $sds-pax-size-60;

        @media (min-width: 48em) {
            border-bottom: none;
            padding-bottom: $sds-pax-size-80;
        }
    }
}

$sds-pax-border-radius-s: $sds-pax-size-40;

.flight-updates {
    background: $white;
    border-radius: $sds-pax-border-radius-s;
    max-width: 40.625rem;
    padding: $sds-pax-size-60;
    width: 100%;
}

.flight-updates-title {
    align-items: center;
    color: $sds-pax-color-brand-50;
    display: flex;
    font-size: $sds-pax-size-60;
    font-weight: $sds-pax-font-weight-text-bold;
    margin: 0;

    &:not(:only-child) {
        margin-bottom: $sds-pax-size-60;
    }

    .flight-updates--cancelled &,
    .flight-updates--delayed &,
    .flight-updates--diverted &,
    .flight-updates--gate_changed & {
        color: $sds-pax-color-signal-50;
    }
}

.flight-updates-title__icon {
    color: $sds-pax-color-brand-50;
    display: inline-block;

    .flight-updates--cancelled &,
    .flight-updates--delayed &,
    .flight-updates--diverted &,
    .flight-updates--gate_changed & {
        color: inherit;
    }
}

.flight-updates-title__text {
    .flight-updates--gate_changed & {
        color: $sds-pax-color-signal-50;
    }
}

.flight-updates__description {
    margin-top: -#{$sds-pax-size-30};
    position: relative;
}

.flight-updates__footer {
    margin: 0;
    padding: $sds-pax-size-60 0 0;
    position: relative;

    &::before {
        background: $sds-pax-color-neutral-80;
        content: "";
        display: block;
        height: $sds-pax-size-10;
        left: 0;
        position: absolute;
        right: 0;
        top: -#{$sds-pax-size-10};
    }
}

.rw-title {
    .itinerary-v2 & {
        margin: $sds-pax-size-100 0 $sds-pax-size-80;

        @media (min-width: 48em) {
            margin: $sds-pax-size-170 0 $sds-pax-size-100;
        }
    }
}

.rw-title__main {
    .itinerary-v2 & {
        font-style: $sds-pax-font-size-text-m;
        line-height: $sds-pax-line-height-text;
        margin-bottom: 0;
    }
}

.rw-title__sub {
    .itinerary-v2 & {
        font-size: $sds-pax-font-size-h1-mobile;
        line-height: 1.1;
        max-width: none;

        @media (min-width: 40em) {
            font-size: $sds-pax-font-size-h1-desktop;
            max-width: 26.875rem;
        }
    }
}

.preparation-tips {
    list-style-type: none;
    margin: $sds-pax-size-50 0 $sds-pax-size-30;
    padding: 0;
}

.preparation-tips__item {
    padding-left: $sds-pax-size-100;
    position: relative;

    & + & {
        margin-top: $sds-pax-size-100;

        &::before {
            background: $sds-pax-color-neutral-150;
            content: "";
            height: $sds-pax-size-10;
            left: 0;
            position: absolute;
            right: 0;
            top: -$sds-pax-size-60;
        }
    }
}

.preparation-tips__heading {
    font-size: inherit;
}

.preparation-tips__body {
    margin-bottom: $sds-pax-size-30;
}

.list-contact {
    @include list-unstyled;
    display: flex;
    flex-direction: column;
    margin: 0;

    @media (min-width: 48em) {
        flex-direction: row;
    }
}

.list-contact__item {
    flex: 1 1 auto;
    padding: 0;
    position: relative;

    & + & {
        margin-top: $sds-pax-size-60;
        padding-top: $sds-pax-size-60;

        &::before {
            background: $sds-pax-color-neutral-90;
            content: "";
            display: block;
            height: $sds-pax-size-10;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        @media (min-width: 48em) {
            margin-top: 0;
            padding-left: $sds-pax-size-60;
            padding-top: 0;

            &::before {
                bottom: -#{$sds-pax-size-60};
                height: auto;
                left: 0;
                right: auto;
                top: -#{$sds-pax-size-60};
                width: $sds-pax-size-10;
            }
        }
    }
}

.rw-title__sub--flights {
    .itinerary-v2 & {
        background-image: linear-gradient($sds-pax-color-gradient-50);
    }
}

.itinerary__wmq-wrapper {
    overflow-x: hidden;
}

.wmq-starting-point {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    @media (min-width: 24.4375em) {
        align-items: normal;
    }

    @media (min-width: 30em) {
        align-items: flex-end;
    }

    &[hidden] {
        display: none;
    }
}

.wmq-starting-point__img {
    @media (min-width: 30em) {
        flex: 0 0 auto;
    }
}

.wmq-starting-point__img--mobile {
    @media (min-width: 30em) {
        display: none;
    }
}

.wmq-starting-point__img--desktop {
    display: none;

    @media (min-width: 30em) {
        display: block;
    }
}

.itinerary-steps-toggle {
    padding-bottom: 0.5rem;
    padding-top: 1rem;

    @media (min-width: 64em) {
        padding-bottom: 0;
    }

    &::before {
        background: $sds-pax-color-neutral-70;
        border: 0;
        border-radius: $sds-pax-size-50;
        bottom: $sds-pax-size-40;
        left: 10px;
        top: 43px;
        transform: translateX(-$sds-pax-size-10);
        width: $sds-pax-size-20;
    }
}

.itinerary-steps-toggle__button {
    background: transparent url($background-icons-path + "/rw-checkmark-itinerary--grey.svg") no-repeat -0.125rem 0;
    border-radius: 2.25rem;
    cursor: pointer;
    padding-left: 2.25rem;
    padding-right: 0.5rem;
    position: relative;
    z-index: 1;

    &:hover {
        background: transparent url($background-icons-path + "/rw-checkmark-itinerary--grey.svg") no-repeat -0.125rem 0;
    }

    &:focus {
        outline: 0;
    }

    &:focus-visible {
        outline: 0.125rem dotted #aa3191;
        outline-offset: 0.375rem;
    }


    &[aria-expanded="true"] {
        background: url($background-icons-path + "/rw-remove-itinerary--grey.svg") no-repeat -0.125rem 0;

        &:focus,
        &:hover {
            background: url($background-icons-path + "/rw-remove-itinerary--grey.svg") no-repeat -0.125rem 0;
        }
    }
}
