// *****************************************************************************
//
// legenda.scss
//
// *****************************************************************************

.legenda {
    text-align: right;
}

.rw-icon {
    .legenda & {
        height: $sds-pax-size-110;
        vertical-align: middle;
        width: auto;
    }
}

.rw-icon-link--active,
.rw-icon-link--active:visited {
    color: $sds-pax-color-text-primary;
    font-weight: bold;
}

.rw-icon-link__text {
    .rw-icon-link--active & {
        border-bottom: $sds-pax-size-10 solid $sds-pax-color-brand-50;
    }
}
