.call-to-action-block__body {
    @media (min-width: 40em) {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: 64em) {
        flex-wrap: nowrap;
    }
}

.call-to-action-block__content {
    max-width: 40.625em;

    @media (min-width: 40em) {
        flex: 1 1 100%;
    }

    @media (min-width: 64em) {
        flex: 1;
        margin-right: $sds-pax-size-70;
    }
}

.call-to-action-block__title {
    margin-top: 0;
}

.call-to-action-block__actions {
    display: flex;
    flex-direction: column;

    @media (min-width: 40em) {
        flex-direction: row;
    }

    // .rw-button
    > * {
        flex-shrink: 0;
    }

    // .rw-button + .rw-button
    // stylelint-disable-next-line
    > * + * {
        margin-top: $sds-pax-size-70;

        @media (min-width: 40em) {
            margin: 0 0 0 $sds-pax-size-90;
        }
    }
}
