$china-pink: $sds-pax-color-retail-2250;
$china-aqua: $sds-pax-color-neutral-150;
$china-mid-blue: $sds-pax-color-retail-1550;
$china-yellow: $sds-pax-color-retail-150;
$china-new-year: $sds-pax-color-retail-250;
$china-new-year-bg: #d4aad0;

.rw-header-minimal {
    .rw-header-minimal__logo {
        fill: $sds-pax-color-brand-150;
    }
    .page-welcome-china & {
        background-color: $china-new-year;
        justify-content: space-around;
        position: fixed;
        width: 100%;
        z-index: 100;
    }
}

.china-hero {
    background: $china-new-year-bg;
    padding-bottom: $sds-pax-size-60;
    padding-top: $sds-pax-size-150;

    &.china-hero--new {
        &::after {
            background-color: $china-new-year-bg;
            content: "";
            display: block;
            height: 110%;
            left: 0;
            min-height: 42.5rem;
            position: absolute;
            right: 0;
            top: -#{$sds-pax-size-360};
            transform: skewY(-20deg);
            transition: all 0.5s ease-out;
            z-index: 0;

            @media (min-width: 90em) {
                height: 121%;
            }
        }
    }
}

.rw-ui-inner {
    .china-hero--new & {
        &::before {
            background-color: $sds-pax-color-brand-150;
            content: "";
            display: block;
            height: 65%;
            left: 0;
            position: absolute;
            right: 0;
            top: 92%;
            transform: skewY(-20deg);
            transition: all 0.5s ease-out;
            z-index: 2;

            @media (min-width: 90em) {
                top: 102%;
                height: 75%;
            }
        }
    }
}

.china-hero__content {
    min-height: 32.75rem;
    position: relative;
    z-index: 5;

    @media (min-width: 64em) {
        min-height: 47.375rem;
    }
}

.china-title {
    font-size: $sds-pax-size-100;
    line-height: 1.125;
    margin: 0;
    max-width: 8.5ch;
    padding: $sds-pax-size-120 0 $sds-pax-size-80;

    @media (min-width: 50em) {
        font-size: $sds-pax-font-size-h1-desktop;
    }

    @media (min-width: 64em) {
        font-size: $sds-pax-size-250;
        line-height: 1.0909;
        padding: $sds-pax-size-170 0 $sds-pax-size-110;
    }
}

.china-cta-links {
    @include list-unstyled;
    display: grid;
    grid-gap: $sds-pax-size-40;
    grid-template-columns: repeat(2, 1fr);
    max-width: $sds-pax-size-550;

    @media (min-width: 64em) {
        grid-gap: $sds-pax-size-70;
        max-width: $sds-pax-size-870;
    }
}

.china-cta-links__item {
    padding: 0;
}

.china-cta-links__target {
    background: $china-aqua;
    border: 0;
    border-radius: $sds-pax-size-40;
    box-shadow: 0 $sds-pax-size-20 $sds-pax-size-30 rgba(0, 0, 0, 0.1);
    color: $china-mid-blue;
    display: block;
    font-size: $sds-pax-font-size-h3-mobile;
    height: 100%;
    line-height: 1.333;
    padding: $sds-pax-size-50 $sds-pax-size-50 $sds-pax-size-40;
    text-align: center;
    width: 100%;

    @media (min-width: 64em) {
        font-size: $sds-pax-size-90;
        padding: $sds-pax-size-80 $sds-pax-size-50 $sds-pax-size-50;
    }

    &:hover,
    &:focus {
        background: $china-aqua;
        box-shadow: 0 $sds-pax-size-30 $sds-pax-size-30 rgba(0, 0, 0, 0.25);
    }
}

.china-cta-links__image {
    display: block;
}

.china-map-location {
    color: $china-mid-blue;
}

.rw-icon {
    .china-cta-links__image & {
        @media (min-width: 50em) {
            height: auto;
            width: $sds-pax-size-180;
        }

        @media (min-width: 64em) {
            height: auto;
            width: $sds-pax-size-210;
        }
    }

    .china-map-location & {
        height: $sds-pax-size-80;
        margin-right: $sds-pax-size-30;
        transform: translateY(-#{$sds-pax-size-50});
        vertical-align: middle;
        width: $sds-pax-size-80;
    }
}

.china-cta-links__text {
    border-bottom: $sds-pax-size-10 solid transparent;
    transition: border-bottom-color $transition-fast;

    .china-cta-links__target:hover &,
    .china-cta-links__target:focus & {
        border-bottom-color: inherit;
    }
}

.china-hero__media {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    @media (min-width: 64em) {
        margin-left: 38.169%;
    }
}

.china-hero__image {
    --bg-image-small: url("https://images.ctfassets.net/biom0eqyyi6b/6IipLCDhnHj7FBzFZ2lblv/3c2a9162fcedac5be4e33f476c9eb243/china-welcome-background.png");
    --bg-image-large: url("https://images.ctfassets.net/biom0eqyyi6b/6IipLCDhnHj7FBzFZ2lblv/3c2a9162fcedac5be4e33f476c9eb243/china-welcome-background.png");
    background-image: var(--bg-image-small);
    background-position: top right -#{$sds-pax-size-40};
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: calc(100% - $sds-pax-size-150);
    position: relative;
    top: $sds-pax-size-210;
    z-index: 1;

    @media (min-width: 40em) {
        right: 100px;
    }

    @media (min-width: 64em) {
        height: calc(100% + $sds-pax-size-230);
        background-image: var(--bg-image-large);
        background-position: top 0 right;
    }

    @media (min-width: 90em) {
        right: 50%;
    }
}

.china-wechat {
    bottom: 0;
    position: absolute;

    @media (min-width: 64em) {
        align-items: center;
        bottom: $sds-pax-size-140;
        display: flex;
        flex-direction: row;
    }
}

.china-wechat__title {
    margin: 0 0 $sds-pax-size-30;
}

.china-wechat__logo {
    height: $sds-pax-size-120;
    width: auto;
}

.china-wechat__text {
    @media (min-width: 64em) {
        margin: 0 0 0 $sds-pax-size-80;
    }
}

.icon-link--wechat {
    color: $china-mid-blue;

    @media (min-width: 64em) {
        font-size: $sds-pax-font-size-h1-mobile;
        line-height: 1.1053;
    }

    &::after {
        bottom: 0;
        content: " ";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.rw-toggle {
    .page-welcome-china & {
        margin: 0;
    }
}

.rw-toggle__trigger,
.rw-toggle__panel {
    .page-welcome-china & {
        padding-left: 0;
        padding-right: 0;
    }
}

.trigger-a11y {
    .page-welcome-china & {
        background-image: url($local-images-path + "china/rw-chevron-down--china.svg");
        color: $china-mid-blue;

        &:hover {
            color: $china-mid-blue;
        }

        &:focus {
            color: $china-mid-blue;
            text-decoration: none;
        }
    }

    .page-welcome-china [aria-expanded="true"] & {
        background-image: url($local-images-path + "china/rw-chevron-up--china.svg");
    }
}

a {
    .china-itinerary &,
    .page-welcome-china .rw-toggle & {
        color: $china-mid-blue;
    }

    .china-footer & {
        color: $sds-pax-color-brand-150;

        &:focus {
            outline-color: $sds-pax-color-brand-150;
        }

        &:hover {
            background: $sds-pax-color-background-primary;
            color: $china-mid-blue;
        }
    }
}

.rw-icon-link {
    .page-welcome-china & {
        color: $china-mid-blue;
    }

    .page-welcome-china &.category-entry__link {
        color: $sds-pax-color-brand-150;
        font-size: $sds-pax-font-size-h1-mobile;
        font-weight: bold;
        line-height: 1.4762;
        text-align: center;

        @media (min-width: 50em) {
            font-size: $sds-pax-font-size-h1-mobile;
        }

        &:hover,
        &:focus {
            outline: 0;
        }

        &:focus {
            &::before {
                border: $sds-pax-size-30 solid $sds-pax-color-retail-1550;
            }
        }

        &::before {
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .page-welcome-china &[hidden] {
        display: none;
    }
}

.china-faq {
    padding-top: $sds-pax-size-70;
    z-index: 2;

    @media (min-width: 64em) {
        &::before {
            content: none;
        }
    }
}

.china-overlay {
    background: rgba($china-pink, 0.9);
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.china-overlay__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: $sds-pax-size-270 $sds-pax-size-90 $sds-pax-size-90;
    width: 100%;
}

.china-overlay__close-container {
    bottom: -#{$sds-pax-size-110};
    display: flex;
    flex-direction: row-reverse;
    height: $sds-pax-size-110;
    max-width: 640px;
    position: relative;
    width: 100%;
}

.rw-icon-button {
    .china-overlay & {
        margin: 0; // Removing some extra margin in Safari
    }
}

.china-footer {
    background-color: $china-mid-blue;
    color: $sds-pax-color-brand-150;
    padding-bottom: $sds-pax-size-60;
    padding-top: $sds-pax-size-80;
    z-index: 2;
}

// Copied styles from retail project
.china-itinerary {
    margin-top: #{$sds-pax-size-150};
    padding-bottom: $sds-pax-size-170;
    position: relative;
    z-index: 20;
}

.itinerary {
    .page-welcome-china & {
        margin-bottom: $sds-pax-size-170;
    }
}

.itinerary-wrapper-china {
    max-width: 41.625rem;
}

.itinerary__item,
.relax-heading {
    scroll-margin-top: $sds-pax-size-370;
}

.flights-search--china {
    margin-bottom: $sds-pax-size-70;
    margin-top: $sds-pax-size-50;
}

.category-entries__list {
    list-style: none;
    margin: $sds-pax-size-70 0 0;
    padding: 0;
    position: relative;

    @media (min-width: 50em) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

.category-entries__item {
    padding-left: 0;
}

.category-entry {
    background-color: transparent;
    background-image: var(--bg-image-small);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: $sds-pax-size-280;
    justify-content: center;
    margin: 0;
    padding: $sds-pax-size-60 $sds-pax-size-50;
    position: relative;

    @media (min-width: 50em) {
        background-image: var(--bg-image-large);
    }
}

.rw-icon-link__text {
    .page-welcome-china .category-entry__link & {
        text-shadow: 0 0 $sds-pax-size-40 rgba(0, 0, 0, 0.3);

        .category-entry__link:hover &,
        .category-entry__link:focus & {
            border-bottom: $sds-pax-size-20 solid currentcolor;
        }
    }
}

.rw-icon.arrow-right--shadow {
    .page-welcome-china .category-entry__link & {
        margin-left: -#{$sds-pax-size-50};
        position: relative;
        top: $sds-pax-size-20;
        width: $sds-pax-size-70;
    }
}

.gate {
    .page-welcome-china & {
        font-weight: bold;
    }
}

.itinerary__item {
    .page-welcome-china & {
        &:last-child {
            padding-bottom: 0;

            &::before {
                bottom: 0;
            }
        }

        &::before {
            border-left: $sds-pax-size-10 dashed $sds-pax-color-neutral-70;
            bottom: -#{$sds-pax-size-30};
            content: "";
            left: $sds-pax-size-40;
            position: absolute;
            top: $sds-pax-size-40;

            @media (min-width: 64em) {
                left: $sds-pax-size-50;
            }
        }

        &::after {
            background: radial-gradient(
                    ellipse at center,
                    $sds-pax-color-background-primary $sds-pax-size-30,
                    transparent $sds-pax-size-30
                ),
                linear-gradient(
                    135deg,
                    $sds-pax-color-brand-50 0%,
                    $sds-pax-color-brand-50 100%
                );
        }
    }
}

.stored-flight-info--china {
    left: 0;
    position: sticky;
    right: 0;
    top: $sds-pax-size-150;
    z-index: 999;

    &:empty {
        display: none;
    }
}

.china-search-icon {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 0;
    bottom: 0;
    color: $sds-pax-color-neutral-60;
    display: inline-flex;
    height: auto;
    justify-content: center;
    margin: 0;
    padding: $sds-pax-size-30;
    position: absolute;
    right: 0;
    top: 0;
    width: $sds-pax-size-140;
}

.rw-select {
    .page-welcome-china & {
        background-image: url($local-images-path + "china/rw-chevron-down--china.svg");
        color: $china-mid-blue;
    }
}
