.my-schiphol-benefits {
    display: flex;
    flex-direction: column;

    @media (min-width: 48em) {
        flex-direction: row;
    }
}

.my-schiphol-benefits__item {
    & + & {
        display: none;

        @media (min-width: 48em) {
            display: block;
        }
    }

    @media (min-width: 48em) {
        flex: 1 1 auto;
        width: 50%;
    }
}

ul {
    .my-schiphol-benefits__item & {
        list-style-type: none;
        margin: $sds-pax-size-100 0;
        padding-left: 0;
    }
}

li {
    .my-schiphol-benefits__item & {
        margin-bottom: $sds-pax-size-70;
        min-height: $sds-pax-size-90;
        padding-left: $sds-pax-size-130;
        position: relative;

        &::before {
            // Encode svg code to 👇🏽 https://codepen.io/jakob-e/pen/doMoML?editors=0110
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E %3Ccircle cx='14' cy='14' r='14' style='fill: %23aa3191'/%3E %3Cpolygon points='11.3 17.3 7.8 13.8 6.5 15 11.3 19.8 21.5 9.4 20.3 8.2 11.3 17.3' style='fill:%23fff'/%3E %3C/svg%3E");
            background-repeat: no-repeat;
            content: "";
            height: $sds-pax-size-90;
            left: $sds-pax-size-10;
            position: absolute;
            top: -#{$sds-pax-size-40};
            width: $sds-pax-size-90;
        }
    }
}
