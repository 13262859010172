.form-controls-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: $sds-pax-size-120 0 0;
    padding-bottom: $sds-pax-size-40;

    @media (min-width: 48em) {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.my-travel-day__dropdown {
    display: inline-flex;
}

.separator {
    padding-bottom: $sds-pax-size-70;

    @media (min-width: 64em) {
        border-bottom: $sds-pax-size-10 solid $sds-pax-color-neutral-70;
    }

    &::before {
        background: $sds-pax-color-neutral-70;
        bottom: 0;
        content: "";
        height: $sds-pax-size-10;
        left: $sds-pax-size-70;
        position: absolute;
        right: $sds-pax-size-70;

        @media (min-width: 64em) {
            content: none;
        }
    }
}

.crowd-indicator__icon {
    margin-left: $sds-pax-size-40;
}

.rw-input__select {
    .my-travel-day__dropdown & {
        margin-bottom: 0;
    }
}

.rw-button {
    .my-travel-day__dropdown & {
        margin-bottom: $sds-pax-size-80;
        margin-left: $sds-pax-size-40;
        vertical-align: top;

        .javascript & {
            display: none;
        }
    }
}

// -----------------------------------------------------------------------------
// My travel day detail page + Tier 1 message
// -----------------------------------------------------------------------------

.rw-icon-link--takeover {
    font-size: $sds-pax-size-70;
    font-weight: bold;
    margin-bottom: $sds-pax-size-170;
    margin-top: $sds-pax-size-40;
}

.meta {
    border-bottom: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
    font-size: $sds-pax-font-size-text-s;
    margin: $sds-pax-size-90 0 $sds-pax-size-120;
    padding-bottom: $sds-pax-size-50;
}

.rw-icon-link {
    .takeover & {
        font-size: $sds-pax-size-70;
        font-weight: bold;
        margin-top: $sds-pax-size-40;
    }
}
