.privium-product-cta--stacked {
    display: block;
}

.privium-product-cta__aside {
    max-width: 450px;
    text-align: center;

    .wrapper--column-gap .privium-product-cta--stacked & {
        margin-left: 0;
        padding-top: 0.625rem;
    }
}

.privium-product-cta__aside--main {
    background-image: url($local-images-path + "privium-product-cta-background.png");
    background-size: cover;
    color: $sds-pax-color-brand-150;
    display: flex;
    flex-direction: column;
    height: $sds-pax-size-430;
    justify-content: space-around;
    padding: $sds-pax-size-100 $sds-pax-size-40;
    text-align: center;

    .privium-product-cta--stacked & {
        background: transparent;
        color: $dusk-green;
        height: auto;
        padding: 0;
        text-align: left;
    }
}

.privium-product-cta__aside__price {
    font-size: $sds-pax-size-90;
    font-weight: 900;
    margin: 0;

    .privium-product-cta--stacked & {
        margin-bottom: 1rem;
    }
}

.privium-product-cta__aside--link {
    margin-top: $sds-pax-size-60;
}
