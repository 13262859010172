// BASE
//

.transfer-kiosk {
    color: $sds-pax-color-text-primary;
    min-height: 100vh;
    padding: $sds-pax-size-170 $sds-pax-size-150;
}

.rw-canvas {
    .transfer-kiosk & {
        box-shadow: none;
    }
}

// HEADER
//
.transfer-kiosk-header {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
}

.transfer-kiosk-header__link {
    align-items: flex-end;
    border-bottom: 0;
    display: flex;
    font-size: $sds-pax-size-80;
    font-weight: bold;
    text-decoration: none;
}

.transfer-kiosk-header__tagline {
    color: $sds-pax-color-wayfinding-150;
    margin-left: $sds-pax-size-60;
}

.rw-button {
    .transfer-kiosk-header & {
        margin-left: auto;
    }
}

// INDEX
.transfer-kiosk--start {
    background-image: linear-gradient(
        90deg,
        $sds-pax-color-wayfinding-50 0,
        $sds-pax-color-wayfinding-50 50%,
        $sds-pax-color-neutral-150 50%,
        $sds-pax-color-neutral-150 100%
    );
}

.transfer-kiosk-index__wrapper {
    display: flex;
    margin: $sds-pax-size-270 auto 0;
    padding-top: $sds-pax-size-500;
    position: relative;
}

.rw-icon--transfer {
    color: $sds-pax-color-wayfinding-150;

    .transfer-kiosk-index__wrapper & {
        height: $sds-pax-size-450;
        left: 0;
        position: absolute;
        top: 0;
        width: $sds-pax-size-450;
    }
}

.transfer-kiosk-index__title {
    color: $sds-pax-color-wayfinding-150;
    flex: 1;
    font-size: $sds-pax-size-280;
    margin-top: 0;
}

.transfer-kiosk-index__content {
    flex: 1;
}

.transfer-kiosk-index__tagline {
    font-size: $sds-pax-font-size-h1-mobile;
    font-weight: bold;
    margin: $sds-pax-size-100 0 $sds-pax-size-70;
    max-width: 450px;
}

.transfer-kiosk-index__form {
    margin-left: $sds-pax-size-170;
    width: 560px;
}

.transfer-kiosk-index__search-bar {
    display: flex;
    padding: 0;
}

.transfer-kiosk-index__search_fields {
    flex-grow: 1;
}

.rw-autosuggest {
    .transfer-kiosk-index__wrapper & {
        flex-grow: 1;
    }
}

// LIST
//

.transfer-kiosk--list {
    background-color: $sds-pax-color-neutral-150;
}

// DETAILS
//
//
.transfer-kiosk--details {
    background-color: $sds-pax-color-neutral-150;
    display: flex;
    flex-direction: column;
}

.transfer-kiosk__title {
    margin-top: 0;
}

.transfer-kiosk__tagline {
    font-size: $sds-pax-size-90;
    margin-bottom: $sds-pax-size-40;
}

.flight-status {
    .transfer-kiosk--details & {
        font-size: $sds-pax-font-size-h2-mobile;
    }
}

.transfer-kiosk__details-title {
    display: block;
}

.flight-status--gate-changed {
    .transfer-kiosk--details & {
        font-size: $sds-pax-font-size-text-s;
    }
}

.flight-information__details--primary {
    margin-bottom: $sds-pax-size-280;
}

.transfer-kiosk__flight-details {
    background-color: $sds-pax-color-brand-150;
    padding: $sds-pax-size-220;
}

.flight-information__details {
    color: $sds-pax-color-text-primary;
    display: flex;
}

.gate__number {
    .transfer-kiosk--details & {
        font-size: $sds-pax-font-size-h1-desktop;
        margin-bottom: $sds-pax-size-50;
        padding: 0 $sds-pax-size-50;
    }
}

.flight-information__item {
    .transfer-kiosk--details & {
        flex: 0 0 38%;
        max-width: 38%;

        &:first-child {
            flex: 0 0 24%;
        }

        &:not(:first-child) {
            padding-left: $sds-pax-size-150;
        }
    }
}

.flight-information__h {
    .flight-information__details & {
        font-size: $sds-pax-size-80;
        margin-bottom: 0;
    }

    .flight-information__details--primary & {
        font-size: $sds-pax-size-90;
    }
}

.transfer-kiosk__time {
    .flight-information__details--primary & {
        margin-bottom: $sds-pax-size-50;
        padding: 0;
    }

    .time-scheduled & {
        font-size: $sds-pax-font-size-h2-mobile;
    }
}

.flight-information__description {
    .transfer-kiosk--details & {
        font-size: $sds-pax-size-100;
    }

    .transfer-kiosk--details--primary & {
        font-size: $sds-pax-size-160;
    }
}

.status-flight {
    .flight-information__details--primary & {
        background-color: $sds-pax-color-brand-50;
        border-radius: $sds-pax-size-30;
        color: $sds-pax-color-brand-150;
        display: block;
        font-size: $sds-pax-font-size-text-s;
        margin-top: $sds-pax-size-50;
        padding: $sds-pax-size-30 $sds-pax-size-40;
        position: absolute;
        text-transform: uppercase;
    }
}

.gate__message {
    .transfer-kiosk--details & {
        font-size: $sds-pax-font-size-h2-mobile;
        font-weight: normal;
        margin-top: 0;
        position: static;
    }
}

// COMMON
//

main {
    .transfer-kiosk--list & {
        margin-top: $sds-pax-size-90;
    }

    .transfer-kiosk--details & {
        margin: auto;
        width: 70vw;
    }
}
