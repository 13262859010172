.page-waiting-time-live,
.page-waiting-time-live body {
    height: 100%;
}

.waiting-time-live {
    align-items: center;
    background: $sds-pax-color-brand-150;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.waiting-time-live__body {
    align-items: center;
    display: flex;
    font-size: 3.3717vw;
    line-height: 1.4;
    margin: 0 auto;
    padding: 0 1.9925% 0 5.8333%;
    position: relative;
    width: 100%;
    z-index: 10;
}

.waiting-time-live__icon {
    flex: 0 0 auto;
    margin-top: -$sds-pax-size-70;
    width: 18.986%;
}

.rw-icon {
    .waiting-time-live__icon & {
        display: none;
        height: auto;
        width: 100%;
    }

    &.rw-icon--security-alt {
        margin-top: -$sds-pax-size-80;
    }

    &.rw-icon--passport-control {
        margin-top: calc($sds-pax-size-60 + $sds-pax-size-20);
    }
}

.waiting-time-live__content {
    margin-top: -$sds-pax-size-130;
    padding-left: $sds-pax-size-140;
    position: relative;
    width: 100%;
}

.waiting-time-live__text {
    display: block;
    height: 1.4em;
    position: relative;
    top: -$sds-pax-size-40;
    width: 100%;
}

.waiting-time-live__time {
    display: block;
    font-size: 10.2083vw;
    font-weight: 900;
    line-height: 1.2;
}

.rw-schiphol-logo__svg {
    .waiting-time-live & {
        bottom: 2.75%;
        height: auto;
        position: absolute;
        right: 2.7397%;
        width: 10.7813%;
        z-index: 1;

        @media (orientation: landscape) {
            bottom: 8.0556%;
            right: 4.4792%;
        }
    }
}

.waiting-time-live-wayfinding {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.waiting-time-live-wayfinding__text {
    align-items: center;
    background: $sds-pax-color-wayfinding-50;
    color: $sds-pax-color-wayfinding-150;
    display: flex;
    font-weight: $sds-pax-font-weight-text-bold;
    justify-content: center;
    padding: $sds-pax-size-70 $sds-pax-size-40 $sds-pax-size-60;
    white-space: nowrap;

    &[hidden] {
        display: none;
    }
}

.waiting-time-live-wayfinding__gates,
.waiting-time-live-wayfinding__numbers {
    font-size: 2.9317vw;
    letter-spacing: 0.1rem;
    line-height: 1;
    margin: -$sds-pax-size-30 $sds-pax-size-30 0;
    position: relative;
}

.waiting-time-live-wayfinding__divider {
    align-self: normal;
    margin: 0 $sds-pax-size-30;
    position: relative;
    width: 0.26vw;

    &::before {
        background: $sds-pax-color-brand-150;
        bottom: $sds-pax-size-60;
        content: "";
        height: 73.4694%;
        left: 0;
        position: absolute;
        right: 0;
        width: $sds-pax-size-30;
    }
}

.waiting-time-live-wayfinding__letters {
    font-size: 5.1275vw;
    line-height: 1;
    margin: 0 $sds-pax-size-30;
    padding: 0;
    text-transform: uppercase;

    .waiting-time-live-wayfinding__number & {
        margin-left: 0;
    }
}

.waiting-time-live-qr {
    align-items: center;
    bottom: $sds-pax-size-190;
    display: flex;
    left: $sds-pax-size-390;
    min-width: 20rem;
    position: absolute;
    width: 23.125%;
    z-index: 1;

    &[hidden] {
        display: none;
    }
}

.waiting-time-live-qr__text {
    aspect-ratio: 2/1;
    flex: 0 0 auto;
    font-size: 2.2167vw;
    line-height: $sds-pax-line-height-h1;
    margin-right: $sds-pax-size-30;
    max-width: 10ch;
    position: relative;
    top: $sds-pax-size-50;
    width: 100%;
}

.waiting-time-live-qr__code {
    border: $sds-pax-size-40 solid $sds-pax-color-brand-150;
    height: auto;
    width: 43.6937%;
}

.waiting-time-live__triangle {
    color: $sds-pax-color-accent-350;
    height: auto;
    position: absolute;
    width: 33.3333%;
}

.waiting-time-live__triangle--tl {
    left: 0;
    top: 0;
    transform: scaleY(-1);
}

.waiting-time-live__triangle--br {
    bottom: 0;
    right: 0;
    transform: scaleX(-1);
    width: 50%;
}

/* entire container, keeps perspective */
.flip-bar {
    display: block;
    perspective: 1000px;
    position: relative;
}

.flip-bar__container {
    display: block;
    position: relative;
    transform-style: preserve-3d;
    transition: 0.6s;

    .waiting-time-live__text & {
        height: 1.4em;
        transform-origin: 100% 0.7em;
    }

    .waiting-time-live-qr__text & {
        height: 3.6em;
        transform-origin: 100% 1.8em;
    }

    .flip-bar--flip & {
        transform: rotateX(-180deg);
    }
}

.flip-bar,
.flip-bar__front,
.flip-bar__back {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

/* hide back of pane during swap */
.flip-bar__front,
.flip-bar__back {
    backface-visibility: hidden;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
}

/* front pane, placed above back */
.flip-bar__front {
    /* for firefox 31 */
    transform: rotateY(0deg);
    z-index: 2;
}

/* back, initially hidden pane */
.flip-bar__back {
    transform: rotateX(180deg);
}

.waiting-times-screen-off {
    background: #000;
}

.waiting-times-screen-off > * {
    display: none;
}

.full-screen-image-container {
    background: #000;
    margin: 0;
    min-height: 100%;
    padding: 0;
    width: 100%;
}

.waiting-time-live-wayfinding__text .rw-icon {
    height: auto;
    margin: $sds-pax-size-40;
    width: 64px;
}
