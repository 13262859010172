.my-travel-day-messages {
    list-style: none;
    margin: 0;
    padding: 0;
}

.my-travel-day-messages__message {
    border-left: $sds-pax-size-40 solid $sds-pax-color-accent-350;
    box-shadow: 0 $sds-pax-size-20 $sds-pax-size-30 0
        rgba($sds-pax-color-brand-50, 0.2);
    margin: $sds-pax-size-70 0;
    padding: $sds-pax-size-90 $sds-pax-size-70 $sds-pax-size-30;

    @media (min-width: 48rem) {
        margin: $sds-pax-size-90 0;
        padding: $sds-pax-size-90 $sds-pax-size-90 $sds-pax-size-30;
    }

    h3 {
        clear: left;
        font-size: $sds-pax-size-70;
        margin: 0 auto $sds-pax-size-50;
    }

    h4 {
        float: left; // make sure this is inline
        font-size: $sds-pax-size-60;
        line-height: 1.5;
        margin: 0 $sds-pax-size-40 0 0;

        &::after {
            content: ": ";
        }
    }
}
