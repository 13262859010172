.live-blog__h {
    font-size: $sds-pax-size-80;
    line-height: 1.18;

    @media (min-width: 64em) {
        font-size: $sds-pax-size-90;
        line-height: 1.14;
        margin-bottom: $sds-pax-size-90;
    }
}

.live-blog-messages {
    max-width: 37.5rem;
}

.rw-list-bullets.live-blog-messages__list {
    margin-bottom: 0;
    margin-top: 0;
}

.rw-list-bullets.live-blog-messages__list--first {
    margin-top: $sds-pax-size-70;

    @media (min-width: 64em) {
        margin-top: $sds-pax-size-90;
    }
}

// Remove padding bottom for the very last item of the live-blog
.rw-list-bullets.live-blog-messages__list:last-child {
    .rw-list-bullets__item.live-blog-messages__list-item:last-child,
    .rw-list-bullets.live-blog-messages__list ~ .rw-list-bullets.live-blog-messages__list:last-child {
        padding-bottom: 0;
    }
}

// Bring dotted line to the last item of every list but for the last list
.rw-list-bullets.live-blog-messages__list:not(:last-child) {
    .rw-list-bullets__item.live-blog-messages__list-item:last-child,
    .rw-list-bullets.live-blog-messages__list ~ .rw-list-bullets.live-blog-messages__list:last-child {
        &::before {
            border-left: $sds-pax-size-10 dashed $sds-pax-color-neutral-70;
            content: "";
            left: $sds-pax-size-40;
            position: absolute;

            @media (min-width: 64em) {
                left: 11px;
            }
        }
    }
}

.rw-list-bullets__item.live-blog-messages__list-item {
    padding: 0 0 $sds-pax-size-120 $sds-pax-size-100;

    @media (min-width: 64em) {
        padding: 0 0 $sds-pax-size-120 $sds-pax-size-160;
    }

    &:last-child {
        @media (max-width: 64em) {
            padding-bottom: $sds-pax-size-90;
        }
    }

    &::before {
        bottom: -#{$sds-pax-size-100};
        top: $sds-pax-size-150;
    }

    &::after {
        background-image: radial-gradient(
                ellipse at center,
                $sds-pax-color-background-primary $sds-pax-size-30,
                transparent $sds-pax-size-30
            ),
            // @TODO - Replace with SDS-token
            linear-gradient(
                    135deg,
                    $sds-pax-color-accent-150,
                    #488aff 34%,
                    $sds-pax-color-accent-50 80%
                );
        top: $sds-pax-size-90;

        @media (min-width: 64em) {
            top: $sds-pax-size-90;
        }
    }
}

.live-blog-message__last-update {
    font-size: $sds-pax-font-size-text-s;
    margin-bottom: $sds-pax-size-40;
}

.updates-available__number-of-updates {
    margin-right: 1ch;
}

.live-blog-message__title {
    font-size: $sds-pax-font-size-text-xl;
    margin-bottom: $sds-pax-size-50;
}

.live-blog-message__body {
    margin-bottom: 0;
}

.live-blog-message__image {
    display: block;
    margin-top: $sds-pax-size-60;

    @media (min-width: 64em) {
        margin-top: $sds-pax-size-70;
    }
}

.live-blog__show-older-button {
    margin-top: $sds-pax-size-130;

    @media (min-width: 64em) {
        margin-top: $sds-pax-size-120;
    }

    .rw-icon {
        margin-left: 0;
    }
}

// Updates button

.updates-available--fixed {
    position: fixed;
    top: $sds-pax-size-100;
    z-index: 1;
}

.updates-available__refresh-icon {
    display: inherit;
    margin-left: $sds-pax-size-40;
}

// Older messages

// Do not show the list of messages until the label is clicked
// Label is the button for showing older messages
.older-messages-input:not(:checked) ~ .live-blog-messages__list,
.older-messages-input:not(:checked) ~ .older-messages-input {
    display: none;
}

// Hide the label once is clicked
.older-messages-input:checked + .live-blog__show-older-button {
    display: none;
}

// Only show the label for the first non checked input
// i.e.: We always show the button for the next list to appear
.older-messages-input:not(:checked) ~ .older-messages-input:not(:checked) + .live-blog__show-older-button {
    display: none;
}

.older-messages-input:focus + .live-blog__show-older-button {
    outline: $sds-pax-color-accent-350 solid $sds-pax-size-30;
}

.rotating {
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
