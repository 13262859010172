.privium-product-card__title {
    margin-bottom: 0;
}

.privium-product-card__description {
    margin: $sds-pax-size-60 0;
}

.privium-product-card__item .rw-icon {
    color: $sds-pax-color-signal-50;
}

.privium-product-card__item--benefit .rw-icon {
    color: $sds-pax-color-privium-50;
}

.privium-product-card__amount {
    color: $sds-pax-color-privium-50;
    font-size: $sds-pax-size-100;
    font-weight: 900;
    margin-right: $sds-pax-size-40;
}

.privium-product-card__cta {
    display: flex;
    flex-flow: column;
}
