// *****************************************************************************
//
// icons.scss
// Old skool icons from CF with icon override
//
// *****************************************************************************

.icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: $sds-pax-size-90;
    color: $sds-pax-color-accent-50;
    display: inline-block;
    height: $sds-pax-size-90;
    width: $sds-pax-size-90;

    svg {
        .icon__foreground {
            fill: currentcolor;
        }

        .icon__fill,
        .icon__line {
            display: none;
        }
    }
}

// Icon override
.icon--line svg {
    /* icons with circle border */
    > g {
        display: none;

        // stylelint-disable-next-line selector-no-qualifying-type
        &.icon__line {
            display: block;
        }
    }
}

.icon--fill svg {
    // icons with background circle
    > g {
        display: none;

        // stylelint-disable-next-line selector-no-qualifying-type
        &.icon__fill {
            display: block;
        }
    }

    .icon__foreground {
        fill: $sds-pax-color-brand-150;
    }

    .icon__background {
        fill: currentcolor;
    }
}

.icon--invert-color {
    // use schiphol blue and not white for foreground colors
    color: $sds-pax-color-text-primary;
}

.icon--error svg {
    .icon__background {
        fill: $sds-pax-color-signal-50;
    }
}

.icon--checkmark {
    stroke: currentcolor;
}

// ----- END overwrite stylize icons -----

.icon-override {
    ul:not([class]) {
        list-style: none;
        margin: $sds-pax-size-50 0;
        padding-left: 0;
    }

    li {
        align-items: center;
        display: flex;
        margin-top: $sds-pax-size-50;
        min-height: $sds-pax-size-90;
        padding-left: $sds-pax-size-120;
        position: relative;
    }

    .icon {
        left: 0;
        margin-right: $sds-pax-size-60;
        position: absolute;
        vertical-align: top;
    }
}
