// *****************************************************************************
//
// table-flights.scss
//
// *****************************************************************************

// Basic table styling
.table-flights {
    border-collapse: collapse;
    border-spacing: 0;
    color: $sds-pax-color-text-primary;
    width: 100%;

    @media (min-width: 48em) {
        font-size: $sds-pax-font-size-text-s;
    }

    caption {
        @include visually-hidden;
    }

    thead {
        @include visually-hidden;

        th {
            font-size: $sds-pax-size-50;
            font-weight: normal;
            padding-bottom: $sds-pax-size-50;
            padding-top: $sds-pax-size-50;
            text-align: left;
        }
    }

    tbody {
        border-top: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
        display: block;
        position: relative;

        &:first-of-type {
            border-top-width: 0;

            .done-searching & {
                @media (max-width: 47.9375em) {
                    border-top-width: $sds-pax-size-10;
                }
            }
        }
    }

    tr {
        display: block;
    }

    td,
    th {
        vertical-align: top;
    }
}

// tbody with flight status class
.table-flights__status {
    padding-bottom: $sds-pax-size-120;
}

.table-flights__airports {
    padding-right: $sds-pax-size-120;
    padding-top: 0;

    @media (min-width: 64em) {
        padding-right: 0;
    }
}

// Table flight custom classes styling
.table-flights__airports,
.table-flights__number,
.table-flights__airline,
.table-flights__message {
    display: block;
    padding: 0 0 0 $sds-pax-size-170;
    padding-left: $sds-pax-size-170;
}

.table-flights__time {
    display: block;
    float: left;
    line-height: 1.5294;
    padding-top: 1.125em;
    text-align: left;
    width: $sds-pax-size-170;

    tbody & {
        font-weight: bold;
        padding-right: 0;
    }
}

.table-flights__airport {
    font-weight: bold;
    padding-bottom: 0.4375em;
    padding-top: 1.125em;
}

.table-flights__direction {
    color: $sds-pax-color-neutral-60;
    font-style: normal;

    @media (min-width: 48em) {
        color: $sds-pax-color-text-primary;
    }
}

.table-flights__stops {
    @include list-unstyled;
    color: $sds-pax-color-neutral-60;
    display: inline;
    margin: 0;

    @media (min-width: 48em) {
        color: $sds-pax-color-text-primary;
    }
}

.flight-info-dialogue__list__stop {
    display: inline-block;
    padding-left: 0;

    &:last-of-type {
        padding-bottom: 0.4375em;
    }

    &:not(:first-child)::before {
        content: ", ";
    }
}

.table-flights__number {
    font-weight: normal;
    padding-right: $sds-pax-size-100;
    text-align: left;
    white-space: nowrap;
}

.table-flights__message {
    bottom: 0;
    left: 0;
    padding-bottom: $sds-pax-size-60;
    position: absolute;
}

.table-flights__details {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;

    &:empty {
        display: none;
    }

    .table-flights & {
        padding-right: 0;
        text-align: right;
    }
}

.table-flights__day {
    display: block;
    padding: $sds-pax-size-50 $sds-pax-size-70;
    text-align: center;
    width: 100%;

    @media (min-width: 64em) {
        padding: $sds-pax-size-50 $sds-pax-size-120;
    }
}

// -----------------------------------------------------------------------------
// Table flights departures/arrivals
// -----------------------------------------------------------------------------

.table-flights--departure {
    .table-flights__airline {
        @include visually-hidden;
    }
}

.table-flights--arrival {
    .table-flights__number {
        @include visually-hidden;
    }
}

// -----------------------------------------------------------------------------
// Table flights XL
// Sometimes you want just a flight data table
// -----------------------------------------------------------------------------

.table-flights--xl {
    // stylelint-disable max-nesting-depth
    thead {
        @media (min-width: 48em), print {
            @include element-visible;

            tr:last-child {
                th {
                    @media (min-width: 48em), print {
                        padding-bottom: 0.4375em;
                    }
                }
            }
        }
    }
    // stylelint-enable max-nesting-depth

    tbody {
        @media (min-width: 48em), print {
            display: table-row-group;
        }
    }

    tr {
        @media (min-width: 48em), print {
            display: table-row;
        }

        &:first-child {
            th,
            td {
                @media (min-width: 48em), print {
                    padding-top: 1.4286em;
                }
            }
        }

        &:last-child {
            th,
            td {
                @media (min-width: 48em), print {
                    height: 100%;
                    padding-bottom: 1.4286em;
                }
            }
        }
    }

    td,
    th {
        @media (min-width: 48em) {
            padding-right: $sds-pax-size-50;
        }

        @media (min-width: 64em), print {
            padding-right: $sds-pax-size-70;
        }
    }

    .table-flights__airports,
    .table-flights__number,
    .table-flights__airline,
    .table-flights__message {
        @media (min-width: 48em), print {
            display: table-cell;
            padding-left: 0;
        }
    }

    .table-flights__time {
        @media (min-width: 48em), print {
            display: table-cell;
            float: none;
            padding-left: $sds-pax-size-70;
            padding-right: $sds-pax-size-60;
            padding-top: 0;
            white-space: nowrap;
            width: auto;
        }

        @media (min-width: 64em) {
            padding-left: 0;
            padding-right: $sds-pax-size-100;
        }
    }

    .table-flights__airline {
        @media (min-width: 48em), print {
            @include element-visible;
            color: inherit;
        }
    }

    .table-flights__stops {
        margin-bottom: 0;
    }

    .table-flights__number {
        @media (min-width: 48em) {
            @include element-visible;
            color: inherit;
            padding-right: $sds-pax-size-50;
            white-space: nowrap;
        }
    }

    .table-flights__airports {
        @media (min-width: 48em), print {
            padding-bottom: $sds-pax-size-70;
        }
    }

    .table-flights__airport {
        @media (min-width: 48em), print {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    .table-flights__message {
        font-weight: bold;

        @media (min-width: 48em), print {
            bottom: auto;
            font-weight: normal;
            left: auto;
            padding-bottom: 0;
            position: static;
            white-space: nowrap;
        }
    }

    .table-flights__details {
        .table-flights & {
            @media (min-width: 48em), print {
                padding-right: $sds-pax-size-110;
            }
        }

        @media (min-width: 48em), print {
            display: table-cell;
            position: static;
            right: auto;
            top: auto;
            transform: none;
        }

        @media print {
            display: none;
        }
    }
}

// -----------------------------------------------------------------------------
// Table flights overlay
// The flight table in the wizard
// -----------------------------------------------------------------------------

.table-flights--overlay {
    border-top: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
    margin: $sds-pax-size-100 -#{$sds-pax-size-70};
    width: calc(100% + $sds-pax-size-70 * 2);

    @media screen and (min-width: 64em) {
        margin: $sds-pax-size-120 -#{$sds-pax-size-120} -#{$sds-pax-size-120};
        width: calc(100% + $sds-pax-size-120 * 2);
    }

    .table-flights__status {
        &:hover {
            &.table-flights__nolink {
                background-color: $sds-pax-color-brand-150;
            }
        }
    }

    .flight-info-result + & {
        margin-top: 0;
    }

    .table-flights__time {
        @media screen and (min-width: 64em) {
            padding-left: $sds-pax-size-120;
        }
    }

    .table-flights__airports {
        padding-right: $sds-pax-size-120;

        @media screen and (min-width: 64em) {
            padding-right: $sds-pax-size-170;
        }
    }

    .table-flights__airports,
    .table-flights__number,
    .table-flights__airline,
    .table-flights__message {
        @media screen and (min-width: 64em) {
            padding-left: $sds-pax-size-270;
        }
    }
}

// -----------------------------------------------------------------------------
// Flight status styling
// -----------------------------------------------------------------------------

.table-flights__status--cancelled,
.table-flights__status--gate-changed,
.table-flights__status--delayed,
.table-flights__status--diverted,
.table-flights__status--tomorrow {
    .table-flights__message {
        color: $sds-pax-color-signal-50;
    }
}

// -----------------------------------------------------------------------------
// No flights
// -----------------------------------------------------------------------------
.table-flights__empty {
    font-weight: bold;
    padding: $sds-pax-size-60 $sds-pax-size-70;

    @media screen and (min-width: 64em) {
        padding: $sds-pax-size-60 $sds-pax-size-120;
    }
}

// -----------------------------------------------------------------------------
// Table on a white background
// -----------------------------------------------------------------------------
.table-flights--color-alpha {
    tbody {
        border-top: $sds-pax-size-10 solid $sds-pax-color-neutral-80;

        &:last-child {
            border-bottom: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
        }
    }

    thead th {
        background-color: $sds-pax-color-brand-150;
        border-bottom: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
    }
}
