.trigger__title {
    margin-top: 0;
}

.trigger__subtitle {
    font-size: $sds-pax-size-60;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: $sds-pax-size-60;
}
