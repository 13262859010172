.covid-branding,
.covid-branding__page,
.covid-branding body,
.covid-branding .rw-canvas,
.covid-branding .main-content,
.covid-branding__page-inner {
    height: 100%;
}

.rw-canvas {
    .covid-branding & {
        box-shadow: none;

        @media (min-width: 120em) {
            margin: 0;
            max-width: none;
        }
    }
}

.covid-branding__page {
    max-width: 1080px;
    overflow: hidden;
    padding: $sds-pax-size-270;
    position: relative;

    @media (min-width: 120em) {
        max-width: 1920px;
    }

    &::before,
    &::after {
        // @TODO - Replace with SDS-token
        background: linear-gradient(
            to bottom,
            #f4cca9 0%,
            #f9d2b5 5%,
            #e8c7be 10%,
            #c1b1bc 15%,
            #c1b1be 20%,
            #bcaebf 25%,
            #b4aac2 30%,
            #a4a3c2 35%,
            #9b9ec1 40%,
            #9498bb 45%,
            #9296bc 50%,
            #8b93ba 55%,
            #8a91bb 60%,
            #7a85b2 65%,
            #737faf 70%,
            #6e7baf 75%,
            #6374a8 80%,
            #5b6ca2 85%,
            #5568a0 90%,
            #4e6199 95%,
            #465993 100%
        );
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: -1;
    }

    &::after {
        background: linear-gradient(
            to bottom,
            #465993 0%,
            #4e6199 5%,
            #5568a0 10%,
            #5b6ca2 15%,
            #6374a8 20%,
            #6e7baf 25%,
            #737faf 30%,
            #7a85b2 35%,
            #8a91bb 40%,
            #9296bc 45%,
            #8b93ba 50%,
            #9498bb 55%,
            #9b9ec1 60%,
            #a4a3c2 65%,
            #b4aac2 70%,
            #bcaebf 75%,
            #c1b1be 80%,
            #c1b1bc 100%
        );
        left: 50%;
    }
}

.covid-branding__background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &::after {
        background: radial-gradient(
            ellipse 200% 25% at 50% 55%,
            #9498bb 26%,
            rgba(148, 152, 187, 0) 100%
        );
        content: "";
        display: block;
        height: 100%;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        z-index: 2;

        @media (min-width: 120em) {
            background: radial-gradient(
                ellipse 80% 35% at 50% 55%,
                #9498bb 26%,
                rgba(148, 152, 187, 0) 100%
            );
        }
    }
}

.covid-branding__svg {
    height: 100%;
    width: 100%;
}

.covid-branding__svg--portrait {
    @media (orientation: portrait) {
        display: block;
    }

    @media (orientation: landscape) {
        display: none;
    }
}

.covid-branding__svg--landscape {
    @media (orientation: portrait) {
        display: none;
    }

    @media (orientation: landscape) {
        display: block;
    }
}

.covid-branding__page-inner {
    background-color: $sds-pax-color-brand-50;
    color: $sds-pax-color-accent-150;
    padding: $sds-pax-size-410 $sds-pax-size-270;
    position: relative;
    z-index: 3;

    @media (min-width: 120em) {
        padding: $sds-pax-size-160 $sds-pax-size-220 0 50%;
    }
}

.container-heading {
    .covid-branding & {
        font-size: $sds-pax-size-280;
    }
}

.extended-content-preview__content {
    .covid-branding & {
        color: $sds-pax-color-brand-150;
        font-size: $sds-pax-size-120;
        font-weight: bold;
        max-width: none;
    }
}

.extended-content-preview__title {
    .covid-branding & {
        display: none;
    }
}

.extended-content-preview__img {
    .covid-branding & {
        margin-right: $sds-pax-size-90;
        width: $sds-pax-size-420;
    }
}

.rw-ui-container {
    .covid-branding & {
        margin-bottom: $sds-pax-size-220;
        margin-top: 0;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media (min-width: 120em) {
            position: static;
        }
    }
}

.usabilla_live_button_container {
    .covid-branding & {
        display: none;
    }
}

.rw-schiphol-logo__svg {
    .covid-branding & {
        bottom: $sds-pax-size-100;
        height: auto;
        position: absolute;
        right: $sds-pax-size-100;
        width: $sds-pax-size-590;
    }
}

.rw-cookie-consent {
    .covid-branding & {
        display: none;
    }
}

// Landscape variant styles
.rw-ui-inner {
    .covid-branding & {
        @media (min-width: 120em) {
            margin: 0;
        }
    }
}

.rw-content-image {
    .covid-branding & {
        @media (min-width: 120em) {
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 50%;
        }
    }
}

.rw-content-image__item {
    .covid-branding & {
        @media (min-width: 120em) {
            margin-bottom: 0;
        }
    }
}
