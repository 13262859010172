.title-gradient {
    color: $sds-pax-color-brand-150;
    display: block;
    font-size: $sds-pax-font-size-h2-mobile;
    line-height: $sds-pax-line-height-h2;
    margin-bottom: $sds-pax-size-70;

    @media (min-width: 48em) {
        font-size: $sds-pax-font-size-h2-desktop;
        margin-bottom: $sds-pax-size-90;
    }
}

.title-gradient + .rw-intro-block {
    margin-top: -$sds-pax-size-60;
    padding-bottom: $sds-pax-size-30;

    @media (min-width: 48em) {
        margin-top: -$sds-pax-size-80;
        padding-bottom: $sds-pax-size-50;
    }
}

.title-gradient--center {
    @media (min-width: 48em) {
        text-align: center;
    }
}

.title-gradient__text {
    color: $sds-pax-color-brand-50;
    display: inline-block;

    /* stylelint-disable property-no-vendor-prefix */
    @supports ((background-clip: text) or (-webkit-background-clip: text)) and (-webkit-text-fill-color: transparent) {
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient($sds-pax-color-gradient-parking-blue);
        -webkit-text-fill-color: transparent;
    }

    @media print {
        background-clip: border-box;
        background-image: none;
        -webkit-text-fill-color: $sds-pax-color-brand-50;
    }
    /* stylelint-enable property-no-vendor-prefix */
}

.grid--parking {
    display: flex;
    flex-wrap: nowrap;
    grid-column-gap: 0.625rem;
    margin-bottom: 0;
    overflow-x: auto;
    padding-bottom: 1.5625rem;


    &:focus-visible {
        outline: $sds-pax-size-20 dotted $sds-pax-color-accent-350;
    }

    @media (min-width: 64em) {
        grid-column-gap: 1.875rem;
    }
}

.rw-card {
    .grid--parking & {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex: 1 0 auto;
        max-width: 22.7081rem;
        width: 13.75rem;

        @media (min-width: 64em) {
            width: 19.375rem;
        }
    }
}

.rw-card__header {
    .grid--parking & {
        margin-bottom: $sds-pax-size-40;
    }
}

.rw-card__body {
    .grid--parking & {
        padding-left: 0;
        padding-right: 0;
    }
}

.rw-card__media-image {
    .grid--parking & {
        aspect-ratio: 1 / 1;
        border-radius: $sds-pax-size-30;
    }
}

.rw-card__media {
    .grid--parking & {
        &::after {
            content: none;
        }
    }
}
