// *****************************************************************************
//
// table-more.scss
// I just made a variation of the rw-icon-link that's only for the flight list
// and it's just at the jobs page. Flight list is going to change so it's
// only for jobs then. That's the reason why it's in Schiphol only
//
// *****************************************************************************

.rw-icon-link--more {
    margin-top: 1.0625em;
    position: static;

    @media (min-width: 48em) {
        margin-top: 0;
    }

    &::before {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        @media (max-width: 47.9375em), print {
            content: "";
        }
    }

    .rw-icon-link__text {
        @media (max-width: 47.9375em) {
            @include visually-hidden;
        }
    }
}
