// *****************************************************************************
//
// support-section.scss
//
// *****************************************************************************

.support-section {
    border-top: $sds-pax-size-10 solid $sds-pax-color-neutral-70;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $sds-pax-size-100;
    padding-top: $sds-pax-size-100;
}

.support-section__bd {
    flex: 1 1 auto;
    order: 1;

    @media (min-width: 40em) {
        order: 0;
    }

    p {
        padding-top: $sds-pax-size-50;
    }
}
