.seaweed {
    display: flex;
    flex-direction: column;

    @media (min-width: 40em) {
        align-items: center;
        flex-direction: row;
    }
}

.seaweed__item {
    &:last-child {
        @media (min-width: 40em) {
            margin-left: $sds-pax-size-170;
        }

        @media (min-width: 64em) {
            padding-right: $sds-pax-size-160;
        }
    }
}

.seaweed__item--text {
    margin-top: $sds-pax-size-60;

    @media (min-width: 40em) {
        flex: 2;
        margin-top: 0;
    }

    @media (min-width: 64em) {
        margin: 0 $sds-pax-size-240;
    }
}

.seaweed__item--image {
    &:last-child {
        order: -1;
    }

    @media (min-width: 40em) {
        flex: 1;

        &:last-child {
            order: 0;
        }
    }
}

.rw-content-image__media {
    .seaweed & {
        height: $sds-pax-size-350;
        width: auto;

        @media (min-width: 40em) {
            height: $sds-pax-size-570;
        }
    }
}
