.benefits__title {
    margin-bottom: 0;
}

.list--benefits {
    margin-top: $sds-pax-size-100;

    .schiphol-advise__timeslots & {
        margin-top: $sds-pax-size-80;
    }
}

.rw-icon {
    .list--benefits & {
        color: $sds-pax-color-accent-50;
        margin-top: $sds-pax-size-10;
    }
}

.rw-list__text {
    .list--benefits & {
        align-self: baseline;
    }
}
