.iframe {
    border: 0;
    display: block;
    min-height: 30rem;
    width: 100%;
}

.iframe-container {
    background: $sds-pax-color-background-primary;
    display: block;
    margin: 0 auto;
    padding: $sds-pax-size-80 0 0;
}

.iframe--request-schipholpas {
    display: none;

    .javascript & {
        display: block;
        height: 100vh;
        margin-top: $sds-pax-size-80;
        min-height: 37.5rem;

        @media (min-width: 40em) {
            height: 37.5rem;
        }
    }
}
