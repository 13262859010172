// *****************************************************************************
//
// amenities.scss
//
// *****************************************************************************

.amenities__h {
    text-align: center;
}

.amenities-list {
    display: flex;
    flex-wrap: wrap;
    font-size: $sds-pax-size-60;
    list-style-type: none;
    margin: 0 0 $sds-pax-size-110;
    padding: 0;
    text-align: center;

    &.amenities-list--itinerary {
        margin-bottom: $sds-pax-size-0;
    }
}

.amenities-list__item {
    flex: 1 0 50%;
    padding-bottom: $sds-pax-size-70;
    padding-left: 0;

    @media (min-width: 48em), print {
        flex: 1 0 25%;
    }

    .amenities-list--itinerary & {
        padding-bottom: $sds-pax-size-0;
    }
}

.amenities-list__link {
    border-bottom: 0;
    color: $sds-pax-color-accent-50;
    display: block;
    text-decoration: none;

    &:hover {
        background-color: transparent;
    }
}

.amenities-list__media {
    display: block;
    height: 4.125em;
    position: relative;
}

.amenities-list__image {
    bottom: $sds-pax-size-60;
    height: $sds-pax-size-100;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: auto;
}

.amenities-list__title {
    color: $sds-pax-color-text-primary;
    display: block;
    font-weight: bold;
}

.amenities-list__description {
    border-bottom: $sds-pax-size-10 solid transparent;

    .amenities-list__link:hover &,
    .amenities-list__link:focus & {
        border-bottom-color: $sds-pax-color-accent-50;
        transition: border-bottom-width $transition-fast,
            border-bottom-color $transition-fast;
    }
}
