.rw-list.rw-list--contact-schiphol {

    & .rw-list__item {
        min-width: 50%;

        @media (min-width: 40em) {
            min-width: auto;
        }
    }
}
