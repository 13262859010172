.project {
    @media (min-width: 40em) {
        border: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
    }
}

.project__img {
    margin: 0;
    padding: 0;

    img {
        width: 100%;
    }
}

.project__block {
    @media (min-width: 40em) {
        display: flex;
        padding: 0.3rem 0 $sds-pax-size-120 $sds-pax-size-120;
    }
}

.project__content {
    @media (min-width: 40em) {
        flex-basis: 60%;
    }
}

.project__usps {
    margin-top: $sds-pax-size-90;

    @media (min-width: 40em) {
        flex-basis: 40%;
        padding-left: $sds-pax-size-140;
    }
}

.project__icon-list {
    list-style: none;
    padding: 0;
}

.project__icon-list-item {
    font-weight: bold;
    padding: $sds-pax-size-40 0;
}

.project__icon {
    display: inline-block;
    height: $sds-pax-size-120;
    margin-right: $sds-pax-size-50;
    vertical-align: middle;
    width: $sds-pax-size-120;

    .icon__foreground,
    .icon__highlight {
        fill: $sds-pax-color-brand-50;

        .project--style-blue & {
            fill: #00a5cf; // @TODO - Replace with SDS-token
        }

        .project--style-black & {
            fill: #e30913; // @TODO - Replace with SDS-token
        }

        .project--style-white & {
            fill: #231f20; // @TODO - Replace with SDS-token
        }
    }
}
