// -----------------------------------------------------------------------------
// Media
// -----------------------------------------------------------------------------

.media {
    background: $sds-pax-color-neutral-150;

    @media (min-width: 40em) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.media__rich {
    display: block;
    height: $sds-pax-size-810;
    overflow: hidden;
    position: relative;

    @media (min-width: 40em) {
        flex: 0 1 auto;
        height: auto;
        min-height: $sds-pax-size-1020;
        padding-bottom: 0;
        width: 50%;
    }
}

.media__img {
    display: block;
    height: 100%;
    left: 50%;
    max-width: none;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: auto;

    @media (min-width: 30.25em) {
        height: auto;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    @media (min-width: 40em) {
        height: 100%;
        left: 50%;
        max-width: none;
        top: 0;
        transform: translateX(-50%);
        width: auto;
    }
}

// This CSS is currently not used. Hence the missing background icon below is only present on the CDN
// https://cdn.schiphol.nl/static/icons/icon/play-media.svg but not in the repo
.media__play {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &::after {
        // background: url($local-images-path + "icons/icon/play-media.svg") no-repeat 0 0;
        content: "";
        display: block;
        height: $sds-pax-size-310;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: $sds-pax-size-310;
    }

    &:focus {
        outline: $sds-pax-size-30 solid $sds-pax-color-accent-350;
        outline-offset: -#{$sds-pax-size-30};
    }
}

.media__body {
    color: $sds-pax-color-text-primary;
    padding: $sds-pax-size-110 $sds-pax-size-70 $sds-pax-size-130;

    @media (min-width: 40em) {
        flex: 0 1 auto;
        padding: $sds-pax-size-180 $sds-pax-size-120 $sds-pax-size-70;
        width: 50%;
    }

    @media (min-width: 48em) {
        padding: $sds-pax-size-180 $sds-pax-size-120 $sds-pax-size-70;
    }

    @media (min-width: 64em) {
        padding: $sds-pax-size-230 $sds-pax-size-190 $sds-pax-size-70;
    }
}

.media__title {
    font-size: $sds-pax-size-80;
    font-weight: 900;
    line-height: 1.1667;
    margin: 0;

    @media (min-width: 48em) {
        font-size: $sds-pax-size-110;
        line-height: 1.2353;
    }
}

.media__text {
    font-size: $sds-pax-size-60;
    line-height: 1.5;

    .media__title + & {
        margin-bottom: $sds-pax-size-80;
        margin-top: $sds-pax-size-50;

        @media (min-width: 48em) {
            margin-bottom: $sds-pax-size-80;
            margin-top: $sds-pax-size-60;
        }
    }
}

.media__link {
    display: block;
    margin: 0 0 $sds-pax-size-60;
}
