// *****************************************************************************
//
// alert.scss
//
// *****************************************************************************

.alert {
    background: $sds-pax-color-background-primary no-repeat left
        $sds-pax-size-50 top $sds-pax-size-50;
    color: $sds-pax-color-text-primary;
    display: block;
    font-weight: normal;
    padding: $sds-pax-size-50 $sds-pax-size-50 $sds-pax-size-50
        $sds-pax-size-120;
    position: relative;

    @media (min-width: 48em) {
        background-position: left $sds-pax-size-70 top $sds-pax-size-70;
        padding: $sds-pax-size-60 $sds-pax-size-60 $sds-pax-size-60
            $sds-pax-size-140;
    }

    .itinerary__details & {
        margin-bottom: $sds-pax-size-30;
    }
}

.alert--inline {
    display: inline-block;
}

p {
    .alert &:only-of-type {
        margin-bottom: 0;
    }
}

.rw-icon--attention {
    .alert & {
        height: $sds-pax-size-80;
        left: $sds-pax-size-40;
        position: absolute;
        top: $sds-pax-size-40;
        width: $sds-pax-size-80;

        @media (min-width: 48em) {
            left: $sds-pax-size-60;
            top: $sds-pax-size-60;
        }
    }
}
