// ----------------------- **************************** ------------------------
//                          Forms schiphol styling
// ----------------------- **************************** ------------------------

// ----------------
// Forms layouts
// ----------------

.page-form {
    background-color: $sds-pax-color-neutral-150;
    height: 100%;
    min-height: 100vh; // needed to fill in the grey bg till the bottom
    padding-top: $sds-pax-size-120;

    .rw-ui-inner {
        max-width: 45.5rem;
    }

    .close-button {
        position: absolute;
        right: $sds-pax-size-60;
        top: $sds-pax-size-60;
    }
}

.form-container {
    background-color: $sds-pax-color-brand-150;
    box-shadow: 0 $sds-pax-size-20 $sds-pax-size-30 0
        rgba($sds-pax-color-brand-50, 0.5);
    margin-bottom: $sds-pax-size-100; // to make the bottom shadow visible
    padding: $sds-pax-size-90;
}

.form-content {
    max-width: 40.625rem;
    padding-right: $sds-pax-size-60;

    .form-content--legend {
        font-size: $sds-pax-size-90;
        margin-bottom: $sds-pax-size-40;
    }
}

// ---------------------
// Form inside layout
// ---------------------

.form-row {
    display: flex;
    flex-direction: column;
    max-width: $sds-pax-size-1010;

    @media (min-width: 40em) {
        flex-direction: row;

        > * {
            flex: 1;
            max-width: 100%;
        }
    }

    > *:not(:first-child) {
        @media (min-width: 40em) {
            margin-left: $sds-pax-size-90;
        }
    }
}

// ---------------------
// Form Privium sidebar
// ---------------------

.form-privium {
    position: relative;
}

.form-sidebar {
    @media (min-width: 40em) {
        position: absolute;
        right: $sds-pax-size-90;
        width: 31%;
    }
}

// ----------------
// Form branding
// ----------------

.form-branding {
    background: no-repeat 50%;
    display: none;

    // Logo position top right of form
    @media (min-width: 40em), print {
        display: block;
        float: right;
        margin-top: -#{$sds-pax-size-70};
    }
}
