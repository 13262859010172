.page-wtbas {
    // CARDS
    .rw-card {
        display: flex;
        flex-direction: row;
    }

    .rw-card__media {
        flex: 0 0 $sds-pax-size-310;
        padding: $sds-pax-size-60 0 0 $sds-pax-size-60;

        img {
            width: $sds-pax-size-270;
        }
    }

    .extended-content-preview {
        margin: 0;

        .extended-content-preview__content {
            flex-direction: column;
        }

        .extended-content-preview__img {
            width: $sds-pax-size-270;

            @media (min-width: 48.1em) {
                width: $sds-pax-size-350;
            }
        }
    }

    .media {
        display: flex;
        flex-direction: column-reverse;

        @media (min-width: 40em) {
            flex-direction: row-reverse;
        }
    }

    .media__body {
        align-items: center;
        display: flex;
        justify-content: center;
        padding-bottom: $sds-pax-size-80;
        padding-top: $sds-pax-size-80;

        @media (min-width: 40em) {
            padding-bottom: $sds-pax-size-100;
            padding-top: $sds-pax-size-100;
        }
    }

    .media__link:last-of-type {
        margin: 0;
    }
}
