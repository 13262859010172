.assistant-block {
    display: block;
    text-align: center;
}

.assistant-block__link {
    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;

    &:hover {
        background: none;
        border: 0;

        .rw-icon-link .rw-icon-link__text {
            border-bottom: $sds-pax-size-10 solid $sds-pax-color-accent-50;
        }
    }
}

.rw-heading-m {
    &.assistant-block__title {
        color: $sds-pax-color-text-primary;
        margin: 0;

        @media (min-width: 40em) {
            margin: 0 $sds-pax-size-70;
        }
    }
}

.assistant-block__text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: $sds-pax-size-70;

    @media (max-width: 20em) {
        margin-left: $sds-pax-size-60;
    }

    @media (min-width: 40em) {
        flex-direction: row;
        margin-left: 0;
    }
}
