.carousel {
    background: $sds-pax-color-background-primary;
    margin: 0 auto;
    position: relative;
    text-align: center;
}

.input-radio {
    .carousel & {
        @include visually-hidden;
    }
}

.carousel__bullet {
    background-color: $sds-pax-color-neutral-60;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: $sds-pax-size-50;
    margin: 0 $sds-pax-size-30 0 0;
    width: $sds-pax-size-50;
    z-index: 100;

    &:active {
        bottom: $sds-pax-size-60;
    }
}

.carousel__container {
    margin: $sds-pax-size-60 0 0;
    width: 100%;
}

.carousel__list-of-slides {
    @include list-unstyled;
    margin: 0;
    overflow: hidden;
    position: relative;

    // This min-height is there because sometimes there can be some dynamic text under one of or each slide
    @media (min-width: 40em) {
        min-height: 453px;
    }

    .wrapper__item & {
        @media (min-width: 40em) {
            min-height: 0;
        }
    }
}

// Slides are stacked
.carousel__slide {
    background-color: $sds-pax-color-brand-150;
    left: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease-in;
    width: 100%;
    z-index: 1;
}

.carousel__image {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    user-select: none;
}

.carousel__arrows {
    display: none;
    line-height: 1;
    padding: $sds-pax-size-40 $sds-pax-size-60 $sds-pax-size-30 $sds-pax-size-40;
    position: absolute;
    top: -#{$sds-pax-size-30};
    z-index: 3;

    @media (min-width: 40em) {
        padding: $sds-pax-size-50 $sds-pax-size-80 $sds-pax-size-50
            $sds-pax-size-50;
        top: $sds-pax-size-590;
    }
}

.rw-icon:first-child {
    .carousel__arrows & {
        margin-left: $sds-pax-size-30;
        margin-right: -#{$sds-pax-size-40};
    }
}

.carousel__description {
    margin-top: $sds-pax-size-50;
}
