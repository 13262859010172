.extended-content-preview__content {
    max-width: 45rem;

    .extended-content-preview--img-none &,
    .extended-content-preview--compact & {
        max-width: 40.625rem;
    }

    @media (min-width: 48em) {
        display: flex;
    }
}

.extended-content-preview__media {
    width: $sds-pax-size-350;

    @media (min-width: 48em) {
        width: $sds-pax-size-600;
    }

    .extended-content-preview--compact & {
        @media (min-width: 48em) {
            width: $sds-pax-size-350;
        }
    }
}

.extended-content-preview__img {
    display: block;
    margin-bottom: $sds-pax-size-50;

    @media (min-width: 48em) {
        margin-bottom: 0;
        width: $sds-pax-size-660;
    }

    .extended-content-preview--img-none &,
    .extended-content-preview--silent & {
        display: none;
    }

    .extended-content-preview--compact & {
        @media (min-width: 48em) {
            width: $sds-pax-size-400;
        }
    }
}

.extended-content-preview__bd {
    @media (min-width: 48em) {
        flex: 1;
    }
}

.extended-content-preview__links {
    @include list-unstyled;
    margin: $sds-pax-size-70 0 $sds-pax-size-60;
}

.extended-content-preview__item-links {
    margin-bottom: $sds-pax-size-40;
    padding: 0;
}

.extended-content-preview__download {
    .extended-content-preview__links & {
        padding-left: 0;
    }
}

.extended-content-preview__download--metadata {
    color: $sds-pax-color-text-primary;
}

p:last-child {
    .extended-content-preview__bd & {
        margin-bottom: 0;
    }
}
