.sre-map {
    line-height: 0;
    position: relative;

    @media (min-width: 48em) {
        border: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
    }
}

.rw-mapbox-wrapper {
    left: 0;
    top: 0;
    width: 100%;
}

.sre-map__tab + .rw-mapbox-wrapper {
    @media (min-width: 48em) {
        position: absolute;
    }
}

.sre-map__tab {
    background-color: $sds-pax-color-brand-150;
    display: inline-block;
    max-width: 32.9375rem;
    padding: $sds-pax-size-60;
    position: relative;
    z-index: 1;

    @media (min-width: 38em) {
        padding: $sds-pax-size-60;
    }

    @media (min-width: 48em) {
        height: 67vh;
        overflow-y: scroll;
    }
}

.sre-map__list {
    margin: 0;
    padding: 0;
    width: 100%;

    @media (min-width: 38em) and (max-width: 47.9375em) {
        display: grid;
        grid-column-gap: $sds-pax-size-100;
        grid-row-gap: $sds-pax-size-100;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 48em) {
        max-width: $sds-pax-size-820;
    }

    @media (min-width: 64em) {
        max-width: 30rem;
    }
}

.rw-card {
    .sre-map__list & {
        padding: 0;
    }

    .rw-card__content {
        .sre-map__list & {
            font-size: $sds-pax-font-size-text-s;
            line-height: 1.75;
        }
    }
}

.rw-card:not(:last-child) {
    .sre-map__list & {
        margin: 0 0 $sds-pax-size-60;

        @media (min-width: 38em) and (max-width: 47.9375em) {
            margin: 0;
        }
    }
}

.sre-map-marker {
    background-image: url($background-icons-path + "/rw-location--blue.svg");
    background-position: -#{$sds-pax-size-40} 0;
    background-size: cover;
    height: $sds-pax-size-140;
    width: $sds-pax-size-90;
}

.sre-map-property-card.is-selected {
    background: $sds-pax-color-accent-50;
    color: $sds-pax-color-brand-150;

    .rw-card__media::after {
        border-bottom-color: $sds-pax-color-accent-50;
    }

    .rw-card__link {
        color: $sds-pax-color-brand-150;
    }

    @media (min-width: 64em) {
        .rw-card__media::after {
            border-bottom-color: transparent;
            border-right-color: $sds-pax-color-accent-50;
        }
    }
}

.rw-map-area-popup-body {
    margin-right: $sds-pax-size-60;
}
