.calamity-block {
    background-color: $sds-pax-color-neutral-150;
    padding: $sds-pax-size-80;
}

.calamity-block__title {
    margin: 0;
}

.calamity-block__panel {
    margin-top: $sds-pax-size-50;
    padding-bottom: $sds-pax-size-40;
}

.rw-icon {
    // button generated by js (rw-visibility-switch)
    .calamity-block__title & {
        color: $sds-pax-color-accent-50;
    }
}
