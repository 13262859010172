.crowd-indicator {
    background: $sds-pax-color-neutral-150;
    margin: -#{$sds-pax-size-30} 0 $sds-pax-size-120;
    max-width: 40.625rem;
    padding: $sds-pax-size-60 $sds-pax-size-70;

    .flight-details-itinerary & {
        background-color: $sds-pax-color-brand-150;
        margin-bottom: $sds-pax-size-80;
    }
}

.crowd-indicator__header {
    font-size: $sds-pax-size-70;
    line-height: 1.2;
    margin-bottom: $sds-pax-size-40;
}

.crowd-indicator__crowd {
    font-size: $sds-pax-size-60;
    line-height: 1.25;
    margin-bottom: 0;
}

.crowdedness {
    color: $sds-pax-color-accent-350;
}

.crowd-indicator__icon {
    display: inline-block;
    vertical-align: middle;
}

.rw-ui-content[data-crowd="normal"] use:nth-of-type(1n + 3),
.crowd-indicator[data-crowd="normal"] use:nth-of-type(1n + 3) {
    fill: $sds-pax-color-accent-350;
}

.rw-ui-content[data-crowd="busy"] use:nth-of-type(1n + 2),
.crowd-indicator[data-crowd="busy"] use:nth-of-type(1n + 2) {
    fill: $sds-pax-color-accent-350;
}

.rw-ui-content[data-crowd="peak"] use:nth-of-type(1n + 1),
.crowd-indicator[data-crowd="peak"] use:nth-of-type(1n + 1) {
    fill: $sds-pax-color-accent-350;
}

.crowd-indicator__body {
    margin-bottom: 0;
}

p:last-child {
    .crowd-indicator__markdown-content & {
        margin-bottom: 0;
    }
}

.crowd-indicator__link {
    margin-bottom: 0;
    margin-top: 1rem;

    .rw-ui-content & {
        margin-bottom: 2rem;
    }
}
