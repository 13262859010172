.social-block {
    background-color: $sds-pax-color-neutral-150;
    padding: $sds-pax-size-100;
}

.social-block__links {
    @include list-unstyled;
    margin-bottom: 0;
}

.social-block__link {
    display: block;
    margin-bottom: $sds-pax-size-70;
    padding: 0;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 40em) {
        display: inline-block;
        margin-bottom: 0;
        margin-right: $sds-pax-size-180;

        &:last-child {
            margin-right: 0;
        }
    }
}

.rw-icon {
    .social-block & {
        height: $sds-pax-size-110;
        width: $sds-pax-size-110;
    }
}
