.cargo-statistics__table {

    th[scope="row"] {
        font-weight: bold;
    }

    tfoot {
        font-weight: bold;
    }
}

.cargo-statistics__chart {
    display: none;

    @media (min-width: 40em) {
        display: block;
    }
}
