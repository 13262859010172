.search-bar {
    margin: $sds-pax-size-140 0;
    max-width: 40rem;
}

.search-results {
    margin-bottom: $sds-pax-size-140;
    margin-top: $sds-pax-size-140;
}

.search-results__heading {
    padding-bottom: $sds-pax-size-60;
}

.search-results__link {
    border-bottom: none;

    &:hover {
        background-color: transparent;
        border-bottom: $sds-pax-size-10 solid;
        border-bottom-width: $sds-pax-size-20;
        outline: 0;
    }
}

.search-results__list {
    list-style-type: none;
    padding: 0;
}

.search-results__list-item {
    border-bottom: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
    padding: $sds-pax-size-80 0 $sds-pax-size-40;

    &:first-child {
        border-top: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
    }
}
