// Property Detail

.sre-property-detail {
    // NOTE
}

.sre-property-detail__list {
    li {
        margin-bottom: $sds-pax-size-40;
    }
}

@media (min-width: 48em) {
    .sre-property-detail__list {
        column-count: 2;
        list-style-position: inside;
        padding: 0;

        li {
            padding-left: $sds-pax-size-80;
            text-indent: -#{$sds-pax-size-80};
        }
    }
}
