.recaptcha {
    margin: $sds-pax-size-80 0;
}

.recaptcha__load-target {
    margin: 0 0 $sds-pax-size-60;

    @media (max-width: 25.125em) {
        border-radius: $sds-pax-size-30;
        overflow: hidden;
        position: relative;

        &::before {
            background: $sds-pax-color-background-primary;
            bottom: $sds-pax-size-20;
            content: "";
            height: $sds-pax-size-10;
            position: absolute;
            right: 0;
            width: $sds-pax-size-20;
        }

        &::after {
            border: $sds-pax-size-10 solid #d3d3d3; // @TODO - Replace with SDS-token
            border-radius: $sds-pax-size-30;
            bottom: $sds-pax-size-20;
            content: "";
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.recaptcha__no-js-iframe {
    border-style: none;
    min-height: 430px;
    width: $sds-pax-size-780;
}

.rw-input-text--recaptcha {
    display: block;
    min-height: $sds-pax-size-650;
    width: $sds-pax-size-780;
}
