// -----------------------------------------------------------------------------
// Stay up-to-date - A block to subscribe to a social medium (Whatsapp, Twitter, Facebook)
// -----------------------------------------------------------------------------
.stay-up-to-date {
    background-color: $sds-pax-color-neutral-150;
    color: $sds-pax-color-text-primary;
    display: flex;
    margin: $sds-pax-size-70 0 0;
    max-width: $sds-pax-size-820;
    padding: $sds-pax-size-60;
}

.stay-up-to-date__body {
    display: flex;
    flex-direction: column;
}

.stay-up-to-date__description {
    font-size: $sds-pax-font-size-text-s;
    margin-top: $sds-pax-size-30;
}

.rw-icon {
    .stay-up-to-date__icon & {
        color: $sds-pax-color-accent-50;
        height: $sds-pax-size-110;
        margin-right: $sds-pax-size-40;
    }
}
