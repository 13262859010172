// -----------------------------------------------------------------------------
// Banner
// Fix to style the banners okay in Schiphol.nl, needs to be removed
// -----------------------------------------------------------------------------

.rw-banner {
    // This code is here for POC Analytics, ask before removing
    &[hidden] {
        display: none;
    }

    .flight-details-information & {
        margin-bottom: $sds-pax-size-50;
    }

    .main-content--wifi & {
        max-width: none;
    }
}

.rw-banner__gradient {
    .main-content--wifi & {
        background-image: url("https://images.ctfassets.net/biom0eqyyi6b/5HnERFjA9v1ayDVCIEMQoU/353df5f41adfd096af02349bf505ec21/banner-instanbul-medium.png");
        background-position: 50%;
        background-size: cover;
    }
}
