.sre-employees-filter__grid-input {
    display: none;
    grid-row-gap: $sds-pax-size-40;
    margin-bottom: $sds-pax-size-140;

    .javascript &,
    .loading-js & {
        display: grid;
    }
}

.sre-employees-filter__input-name {
    max-width: 100%;
}

.sre-employees-filter__submit {
    @media (min-width: 60em) {
        margin-top: $sds-pax-size-90;
    }
}
