.sms-notifications {
    .download-app-links__item:first-child {
        margin-left: 0;
    }
}

.sms-notifications__h {
    @media (min-width: 48em) {
        text-align: center;
    }
}

.sms-notifications__sub {
    background-color: transparent;
    border: 0;
    color: $sds-pax-color-text-primary;
    font-size: $sds-pax-size-80;
    line-height: 1.25;
    margin: 0 0 $sds-pax-size-40;
}

.sms-notifications__c--small {
    font-size: $sds-pax-font-size-text-s;
}

.sms-notification__button {
    @media (min-width: 48em) {
        display: none;
    }
}
