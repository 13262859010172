.image-gallery {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;

    &::after {
        content: "";
        display: block;
        flex-basis: calc(33.33% - ($sds-pax-size-60 * 0.667));
    }

    @supports (display: grid) {
        display: grid;
        grid-gap: 2vw;
        grid-template-columns: 1fr 1fr;
    }
}

.image-gallery__item {
    flex-basis: calc(33.33% - ($sds-pax-size-60 * 0.667));
    margin-bottom: $sds-pax-size-60;
    padding: 0;
    position: relative;

    @supports (display: grid) {
        margin-bottom: 0;

        &:first-child {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
        }
    }
}

.image-gallery__item-link {
    width: 100%;
}

.image-gallery__item-overlay {
    align-items: center;
    background-color: rgba($sds-pax-color-brand-50, 0.6);
    color: $sds-pax-color-brand-150;
    display: flex;
    font-size: $sds-pax-size-80;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}

@media (min-width: 48em) {
    .image-gallery {
        @supports (display: grid) {
            grid-gap: 2vw;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}
