.privium-product {
    & + & {
        margin-top: $sds-pax-size-90;
    }

    @media (min-width: 40em) {
        & + & {
            border-left: $sds-pax-size-10 solid $sds-pax-color-neutral-80;
            margin-left: $sds-pax-size-100;
            margin-top: 0;
            padding-left: $sds-pax-size-100;
        }
    }
}

.privium-product__title {
    color: $sds-pax-color-privium-50;
}

.privium-product__item .rw-icon {
    color: $sds-pax-color-signal-50;
}

.privium-product__item--benefit .rw-icon {
    color: $sds-pax-color-privium-50;
}

.privium-product__amount {
    font-size: $sds-pax-size-100;
    font-weight: 900;
    margin-right: $sds-pax-size-40;
}
