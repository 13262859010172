// *****************************************************************************
//
// flight-times.scss
// Only needed in Schiphol so that's why only in Schiphol runway
//
// *****************************************************************************

.flight-times--search {
    width: $sds-pax-size-170;

    @media (min-width: 48em) {
        width: 30%;
    }
}

.flight-times__expected-time {
    color: $sds-pax-color-signal-50;
    text-decoration: none;

    // Flights table
    .table-flights--xl & {
        display: inline-block;
        padding-top: 0.375em;

        @media (min-width: 48em) {
            margin-left: $sds-pax-size-30;
            padding-top: 0;
        }

        @media (min-width: 64em) {
            margin-left: $sds-pax-size-60;
        }
    }

    .cargo & {
        color: $sds-pax-color-signal-50;
        margin-left: $sds-pax-size-50;
    }
}
