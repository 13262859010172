// *****************************************************************************
//
// refinement.scss
// When there is a big list with results you want to refine them
// Search / Filter / Flight specific / Legenda
//
// *****************************************************************************

.refinements {
    @media (min-width: 40em) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
}

.refinements__legenda {
    margin-top: $sds-pax-size-80;

    @media (min-width: 40em) {
        margin-left: auto;
        margin-top: 0;
        padding-top: $sds-pax-size-10;
    }
}

.rw-icon {
    .refinements & {
        height: $sds-pax-size-110;
        vertical-align: middle;
        width: auto;
    }
}
