// *****************************************************************************
//
// _airline-info.scss
//
// *****************************************************************************

.airline-info {
    background: $sds-pax-color-background-primary;
    width: 100%;

    .searchresults-page & {
        box-shadow: 0 $sds-pax-size-10 $sds-pax-size-10 0 rgba(0, 0, 0, 0.12);
        max-width: $sds-pax-size-900;
    }
}

.airline-info__branding {
    align-items: center;
    border: $sds-pax-size-40 solid $sds-pax-color-neutral-80;
    display: flex;
    height: $sds-pax-size-520;
    justify-content: center;

    .rw-intro-block & {
        max-width: $sds-pax-size-900;
    }
}

.airline-info__content {
    padding: $sds-pax-size-90 0;

    .searchresults-page & {
        padding: $sds-pax-size-90;
    }
}

.airline-info__h {
    margin-bottom: $sds-pax-size-70;
}

.airline-info__meta {
    list-style: none;
    margin: 0;
    padding: 0;
}

.airline-info__meta-item {
    font-size: $sds-pax-size-60;
    margin-bottom: 0 $sds-pax-size-60;
    padding-left: 0;

    .rw-icon-link .rw-icon {
        height: $sds-pax-size-100;
        margin-right: $sds-pax-size-50;
        width: $sds-pax-size-100;
    }
}

.airline-info__actions {
    margin-top: $sds-pax-size-100;
}

.airline-info__card {
    border-bottom: $sds-pax-size-20 solid $sds-pax-color-neutral-80;
    border-top: $sds-pax-size-20 solid $sds-pax-color-neutral-80;
    font-size: $sds-pax-size-60;
    padding: $sds-pax-size-120 0;

    @media (min-width: 30em) {
        display: flex;
    }
}

.airline-info__bd {
    flex-basis: 70%;
}

.airline-info__sub {
    display: block;
}

.airline-info__sidebar {
    flex-basis: 30%;
}

.markdown-content--airline-info strong:not([class]) {
    display: block;
}
