.schiphol-advise {
    background: $sds-pax-color-brand-150;
    border-radius: $sds-pax-size-40;
    margin-bottom: $sds-pax-size-70;
    padding: $sds-pax-size-60;
    position: relative;
}

.schiphol-advise-header {
    font-size: $sds-pax-size-60;
    font-weight: $sds-pax-font-weight-headings;
    line-height: $sds-pax-size-70;
    margin-bottom: $sds-pax-size-60;

    &:not(:first-child) {
        margin-top: 0;
    }

    &[aria-expanded="false"] {
        margin-bottom: 0;
    }

    &.schiphol-advise-header--border {
        border-bottom: 1px solid $sds-pax-color-neutral-80;
        padding-bottom: $sds-pax-size-60;
    }
}

.schiphol-advise-header__advise {
    color: $sds-pax-color-neutral-50;
    display: block;
    font-size: $sds-pax-size-60;
    font-weight: $sds-pax-font-weight-text;
    line-height: $sds-pax-size-80;
}

.schiphol-advise-header__time {
    color: $sds-pax-color-accent-350;
}

.schiphol-advise__content {
    position: relative;

    &:first-child {
        padding-top: $sds-pax-size-0;
    }
}

.schiphol-advise__timeslots {
    border-top: 1px solid $sds-pax-color-neutral-80;
    margin-top: $sds-pax-size-60;
    padding-top: $sds-pax-size-60;

    &:only-child {
        padding-top: 0;
    }

    &:only-child,
    .schiphol-advise-header + & {
        border: 0;
        margin-top: 0;
    }
}

.timeslots-actions {
    align-items: center;
    display: flex;
    gap: $sds-pax-size-60;
    padding-bottom: $sds-pax-size-40;
}

.schiphol-advise-crowd {
    font-size: $sds-pax-size-60;
    font-weight: $sds-pax-font-weight-text-bold;
    margin-bottom: $sds-pax-size-50;
}

.schiphol-advise-crowd__icon {
    display: inline-block;
    transform: translateY(-$sds-pax-size-20);
    vertical-align: middle;
}

.schiphol-advise[data-crowd="normal"] use:nth-of-type(1n + 3) {
    fill: $sds-pax-color-accent-350;
}

.schiphol-advise[data-crowd="busy"] use:nth-of-type(1n + 2) {
    fill: $sds-pax-color-accent-350;
}

.schiphol-advise[data-crowd="peak"] use:nth-of-type(1n + 1) {
    fill: $sds-pax-color-accent-350;
}

.schiphol-advise__body {
    font-size: $sds-pax-font-size-text-s;
    margin-bottom: 0;
    max-width: 33rem;

    @media (min-width: 48em) {
        font-size: $sds-pax-font-size-text-m;
    }
}

.schiphol-advise__hidden {
    display: none;

    &.active {
        display: block;
    }

    &--inline {
        &.active {
            display: inline;
        }
    }
}

.schiphol-advise-crowd__label {
    &::first-letter {
        text-transform: capitalize;
    }
}

.schiphol-advise-crowd__label--spinner {
    display: none;
    vertical-align: middle;

    &.active {
        display: initial;
    }

    svg {
        height: 32px;
        width: 32px;
    }
}

.rw-toggle {
    .schiphol-advise & {
        margin: 0;
    }
}

.rw-toggle__trigger {
    .schiphol-advise & {
        padding: $sds-pax-size-60 0;
    }
}

.rw-toggle__panel {
    .itinerary-v2 & {
        font-size: $sds-pax-font-size-text-s;
        max-width: 33rem;
        padding: 0 0 $sds-pax-size-60 0;

        @media (min-width: 48em) {
            font-size: $sds-pax-font-size-text-m;
        }
    }
}
