.table-overview {
    margin-bottom: $sds-pax-size-80;
    width: 100%;

    caption {
        font-size: $sds-pax-font-size-text-xl;
        font-weight: bold;
        padding: 0 0 $sds-pax-size-60 $sds-pax-size-20;
        text-align: left;

        .rw-icon {
            height: $sds-pax-size-80;
            width: $sds-pax-size-80;
        }
    }

    th,
    td {
        display: block;

        @media (min-width: 40em), print {
            display: table-cell;
            width: 50%;
        }
    }

    th {
        font-weight: normal;
        text-align: left;
    }

    td {
        font-weight: bold;
    }
}
