// Mapbox
// -----------------------------------------------------------------------------
.mapboxgl-ctrl-attrib a {
    border: 0;

    &:hover,
    &:focus {
        background-color: transparent;
        color: $sds-pax-color-accent-50;
        text-decoration: underline;
    }
}
