.gm-directions {
    background: $sds-pax-color-neutral-150;
    padding: $sds-pax-size-100;
}

.gm-directions__input {
    margin: 0 $sds-pax-size-70 0 0;
    width: 100%;

    @media (max-width: 39.9375em) {
        // Overwrite the default max-width styling on an input field
        max-width: none;
    }
}

.gm-directions__form-components {
    align-items: flex-end;
    margin-bottom: $sds-pax-size-100;

    @media (min-width: 40em) {
        display: flex;
        margin: 0 0 $sds-pax-size-100;
    }
}

.rw-button {
    .gm-directions__form-components & {
        flex: 0 0 auto;
    }
}

.rw-icon--parking-alt {
    .gm-directions & {
        height: $sds-pax-size-140;
        width: $sds-pax-size-140;
    }
}
